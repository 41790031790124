body{
    font-size: 16px;
    
    
        background-color: #f5f5f5;
        margin: 0;
        padding: 0;
        line-height: 26px;
        min-height: 100vh;
        font-family: 'Raleway', sans-serif!important;
         max-width: 100%;
         overflow-x: hidden;
        
 
}
.producdropdown{
    position: absolute;
  
padding:0px;
}


.producdropdownmenu{
    display: block;
}
.productmaincont{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}
.productmainrow{
   
    margin-right: -15px;
    margin-left: -15px;
    background-color:
    #2F2F2F;
    color: #fff;
    width: 75rem;   
    min-height: 450px;  
    transform:translate(2%,0);
    left: 50%;
    top: calc(100% + 3px);
    z-index: 4;
}
.productmaincol1{
    
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    background: #000;
}
.product-menu{
    width: 66.66666667% !important;
    position: absolute;
    background-color: rgba(0,0,0,.7);
    color: #fff;
    min-width: 90vw;
    min-height: 450px;  
    transform:translate(-50%,0);
    left: 71%;
    top: calc(100% + 3px);
    z-index: 4;

}
.prodytdropdownul{
    display: flex;
    flex-wrap: wrap;
    margin-top: 2px;
    min-width: 160px;
    padding: 25px 15px;
    font-size: .94em;
    background-color: black;
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    border: 1px solid rgba(0,0,0,.15);
    background-clip: padding-box;
    text-align: left;
    width: 100%;
   
}
.prodytdropdownliii{
    list-style: none;
    width: 20%;
   
}
.prodytdropdownaaaaaa{
    padding: 10px 7px;
    white-space: normal;
    font-size: .86em;
    letter-spacing: 0;
    display: block;
    color: #fff;
    font-weight: 600;
    line-height: 1.42857143;
    text-decoration: none;
    text-transform: uppercase;
}
.prodytdropdownaaaaaa:hover{
   background-color:#616161;
   text-decoration: underline;
    color: white;
}
.prodytdropsubdownui{
    float: none;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 15px 0;
    display: block;
    position: relative;
    background-image: none;
    background-color: transparent;
    margin: 2px;
}
.prodytdropsubdownlii{
    list-style: none;
}
.hairproduct1aa{
    cursor: pointer;
    padding: 10px 7px;
    white-space: normal;
    font-size: .86em;
    letter-spacing: 0;
    display: block;
    color: #fff;
    font-weight: 600;
    line-height: 1.42857143;
    text-decoration: none;


}
.hairproduct1aa:hover{
    background-color:#616161;
    text-decoration: underline;
     color: white;
 }
 .contactdropbelow{
    width: 46.8%;
    text-align: center;
    border-top: 2px solid gray;
    text-transform: uppercase;
    margin-top: 50px;
    margin-right: auto;
 }
 .contactdropbelowaaa{
    padding: 10px 7px;
    white-space: normal;
    font-size: .86em;
    letter-spacing: 0;
    display: block;
    color: #fff;
   
    font-weight: 600;
    line-height: 1.42857143;
    text-decoration: none;
 }
 .contactdropbelowaaa:hover{
    background-color:#616161;
    text-decoration: underline;
     color: white;
 }
    .mainuiiiii{
        list-style-type: none;
    padding: 0; 
    }
.mainliii{
    display: flex; /* Display top-level list items as block */
  margin-bottom: 10px;
}
.productmaincol2{
    padding: 0;
    margin-left: -11px;
    /* display: flex; */
}
.pructsideimg{

}
.ftrremovimg{
    width: 100%;
    height: 257.4px;
}
.contactintro{
    padding: 35px 25px;
    font-size: 12px;
}
.productsubtitle{
    color: #fff;
    line-height: 26px;
    margin: 0px 0px 20px;
    font-size: 14px;
    font-weight: 600;

}
.productintropp{
    margin: 0;
    padding: 0;
    line-height: 26px;
    font-size: 12px;
    font-weight: 400;
}
.productintroppppp2{
    margin: 0 0 10px;
}
.productintropbutton{
    font-weight: 800;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    background-color: #555556;
    border: 1px solid #fff;
    border-radius: 22px;
    padding: 15px 25px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 12px;
    display: inline-block;
    background-color: #0a71f0;
    white-space: nowrap;
    text-decoration: none;
    
}
.productintropbutton:hover{
    background-color:
   RGB(15, 86, 153);
   text-decoration: underline;
   color: #fff;
}
.product-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    /* Add your styling for the dropdown menu */
  }
  
  .product-dropdown.show {
    display: block;
  }
  @media only screen and (max-width: 767px){
 
    .productmainrow{
        width: 43rem; 
        min-height: 450px;
         margin-top: 35%; 
         transform: translate(9%,0);
      }
      .productmaincont{
        margin-top: 42%;
      }
      .mainuiiiii{

      }
      .mainliii{
        flex-direction: column;
      }
      .prodytdropdownul{
        flex-direction: column;
      }
      .contactdropbelow{
        width: 30%;
      }
      .productmaincol2{
        display: none;
      }
      .producdropdownmenu{
        margin-left: 7%;
      }
      .atfsubnavcontentaaaaa{
        width: 30%;
      }

  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .producdropdown{
        position: absolute;
 
        left: 50%;
        transform: translate(-59%, 0%);
        z-index: 1000;
      }
      .productmainrow{
        width: 43rem; 
        min-height: 450px;
         margin-top: 35%; 
         transform: translate(9%,0);
      }
      .productmaincont{
        margin-top: 50%;
      }
      .prodytdropdownliii ul {
        /* Target the <ul> within each .prodytdropdownliii */
        display: block;
        padding-left: 20px; /* Add some left padding for indentation */
      }
    }
    
    
    .producdropdownmenu{
      
    }
    .producdropdown{}

  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .producdropdown{
        
    }
    
    
    .producdropdownmenu{
        display: block;
    }
    .producdropdown{}
  }
  @media only screen and (min-width: 1200px) and (max-width: 1279px){
  
        .producdropdown{
            position: absolute;
         
            left: 50%;
            transform: translate(-59%, 0%);
            z-index: 1000;
          }
   
  }
  @media screen and (min-width: 1280px){
  .producdropdown{
    position: absolute;
 
    left: 50%;
    transform: translate(-59%, 0%);
    z-index: 1000;
  }
  }