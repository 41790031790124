.top-social-wrapper {
    float: left;
    margin-left: 22px;
    gap: 10px;
    line-height: 20px;
    display: flex;
}

.top-social-wrapper .social-icon {
    float: left;
    margin-left: 10px;
    max-width: 14px;
}

.top-navigation-wrapper a {
    color: #f3f3f3;
}

.aesthetic {
    background-image: url(./images/doctor.png);
    background-size: cover;
    padding: 150px 0;
}

.navbar-brand {
    display: flex;
    align-items: end;
    justify-content: end;
}

.col-6 {
    width: 50%;
}

.rows {
    display: flex;
}

@media only screen and (max-width: 1023px) {
    .rows {
        display: block
    }
}

@media only screen and (max-width: 1023px) {
    .col-6 {
        width: 100%;
    }
}

h2.feature-h2 {
    padding: 100px 30px 0px 0px;
}

p.feature-p {
    padding: 50px 30px 30px 0px;
}

.col-3 {
    width: 25%;
}


/* Yogesh css */

.aa {
    display: inline-flex;
    list-style-type: none;
    color: #fff;
}

.aa li {
    list-style: none;
    display: inline-block;
    padding: 10px 10px;
    font-size: 20px;
}

@media (min-width: 0px) and (max-width: 900px) {
    .aa {
        display: contents;
        color: #fff;
    }
}

/* Desktop styles */
.containers {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #ebe5d9;
}

.resultrow {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.col-3 {
    flex: 0 0 calc(25% - 20px);
    padding: 10px;
    box-sizing: border-box;
}

.group_img {
    max-width: 100%;
    height: auto;
    border: 2px solid black;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* Mobile styles */
@media (max-width: 768px) {
    .containers {
        padding: 10px;
    }

    .resultrow {
        flex-wrap: wrap;
    }

    .col-3 {
        flex: 0 0 calc(50% - 10px);
    }
}


.contrr444 h2 {
    font-size: 40px;
    color: #fff;
    text-align: center;
}

.contrr444 ul {
  
}

.fronth2 h2 {
    font-size: 35px;
    font-weight: normal;
    padding: 0;
}

.newbg_contact {
    
    background-color: #333333;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    opacity: 0.9
}

.footer {
    background: linear-gradient(105deg, #515151, #515151);
    padding-top: 80px;
    padding-bottom: 40px;
}

/*END FOOTER SOCIAL DESIGN*/
.single_footer {}

@media only screen and (max-width:768px) {
    .single_footer {
        margin-bottom: 30px;
    }
}

.single_footer h4 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}

.single_footer h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: #fff;
    margin-top: 20px;
}

.single_footer p {
    color: #fff;
}

.single_footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.single_footer ul li {}

.single_footer ul li a {
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 36px;
    font-size: 15px;
    text-transform: capitalize;
}

.single_footer ul li a:hover {
    color: #04b5ca;
}

.single_footer_address {}

.single_footer_address ul {}

.single_footer_address ul li {
    color: #fff;
}

.single_footer_address ul li span {
    font-weight: 400;
    color: #fff;
    line-height: 28px;
}

.contact_social ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
    display: block;
    position: relative;
    margin-top: 15px;
    width: 100%;
}

.subscribe__input {
    background-color: #fff;
    border: medium none;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: 15px;
    font-weight: 500;
    height: 60px;
    letter-spacing: 0.4px;
    margin: 0;
    padding: 0 150px 0 20px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}

@media only screen and (max-width:768px) {
    .subscribe__input {
        padding: 0 50px 0 20px;
    }
}

.subscribe__btn {
    background-color: transparent;
    border-radius: 0 25px 25px 0;
    color: #01c7e9;
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
}

.subscribe__btn i {
    transition: all 0.3s ease 0s;
}

@media only screen and (max-width:768px) {
    .subscribe__btn {
        right: 0px;
    }
}

.subscribe__btn:hover i {
    color: #04b5ca;
}

button {
    padding: 0;
    border: none;
    background-color: transparent;
    -webkit-border-radius: 0;
    border-radius: 0;
}

/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
    margin-top: 40px;
}

.social_profile ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.social_profile ul li {
    float: left;
}

.social_profile ul li a {
    text-align: center;
    border: 0px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    margin: 0px 5px;
    font-size: 18px;
    color: #fff;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width:768px) {
    .social_profile ul li a {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:480px) {
    .social_profile ul li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

.social_profile ul li a:hover {
    background: #04b5ca;
    border: 1px solid #04b5ca;
    color: #fff;
    border: 0px;
}


.pb-30{
    padding-bottom: 10px;
    border-bottom: 1px solid white;
}