@charset "utf-8";

@media only screen and (min-width:1600px) {
    .topImage.small {
        min-height: 750px
    }
}

@media only screen and (min-width:1231px) {
    .lang_he .navbar-default .container {
        padding: 0 15px
    }
   
}

@media only screen and (max-width:1230px) {
    .navbar-default .container {
        padding: 0 15px
    }
   
}

@media only screen and (min-width:992px) {
    #header .col-md-7 {
        width: 61.333333%
    }

    #header .col-md-5 {
        width: 38.666667%
    }

    .lang_ja #header .col-md-7 {
        width: 70.333333%
    }

    .lang_ja #header .col-md-5 {
        width: 29.666667%
    }

    .lang_bg #header .col-md-7,
    .lang_es #header .col-md-7 {
        width: 76.333333%
    }

    .lang_bg #header .col-md-5,
    .lang_es #header .col-md-5 {
        width: 23.666667%
    }
}

@media only screen and (max-width:1199px) {

    .productCarousel .carousel-control .glyphicon-chevron-left,
    .productCarousel .carousel-control .icon-prev {
        left: 90%;
        margin-left: -10px
    }

    .productCarousel .carousel-control .glyphicon-chevron-right,
    .productCarousel .carousel-control .icon-next {
        right: 90%;
        margin-left: -10px
    }

    .logoSlice>.container {
        width: 98%;
        margin: 0 auto
    }

    .topImage .padding50 {
        padding-top: 0;
        padding-bottom: 0
    }

    .customImgTreatment img {
        max-width: 200%
    }

    .nav>li>a {
        padding: 17px 0 17px 15px
    }

    .topImage h1 {
        font-size: 48px;
        line-height: 1em
    }

    .bannerPaddingRight {
        padding-right: 171px
    }

    .aesthetic {
        background-position: center
    }

    .fix .navbar-nav {
        padding-top: 13px
    }

    h1,
    .title-h1 {
        font-size: 55px
    }

    h2,
    .title-h2 {
        font-size: 38px
    }

    h3,
    .title-h3,
    .newsStackLeft .widget_article h2 {
        font-size: 20px
    }

    h4,
    .title-h4 {
        font-size: 16px
    }

    h5,
    .title-h5 {
        font-size: 18px
    }

    h6,
    .title-h6 {
        font-size: 16px
    }

    .h3clone {
        font-size: 20px
    }

    .productHover {
        min-height: 875px;
        height: auto
    }

    .productHover .btn {
        opacity: 1;
        margin-top: 10px
    }

    .compareProd {
        top: auto;
        bottom: 0;
        z-index: 3;
        position: relative
    }

    .xproductHover .viewProd {
        position: absolute;
        bottom: 60px;
        left: 0;
        right: 0
    }

    .productHover .viewProd {
        position: relative;
        bottom: 0
    }

    body {
        font-size: 15px;
        line-height: 23px
    }

    .programIcon {
        display: block
    }

    .programText {
        padding-left: 0;
        display: block
    }

    .padding175 {
        padding: 100px 0
    }

    .warrantyBG {
        padding-right: 290px
    }

    .partnership,
    .businessDev {
        padding: 100px 0
    }

    .nav.nav-tabs>li>a {
        padding: 20px 50px
    }

    .otherProducts {
        width: 33%
    }

    .otherProducts .pLogo {
        opacity: 1
    }

    .otherProducts span {
        padding: 5px 0
    }

    .otherProducts .pImage {
        transform: scale(1)
    }

    .hoverWrapper {
        -webkit-filter: grayscale(0%) !important;
        -moz-filter: grayscale(0%) !important;
        -ms-filter: grayscale(0%) !important;
        -o-filter: grayscale(0%) !important;
        filter: none !important;
        border: 3px solid #fff
    }

    .regions table td img {
        margin-right: 15px;
        display: block;
        margin-bottom: 10px
    }

    .productHeader.topImage {
        padding: 40px 0 45px
    }

    .newsTop {
        xbackground-position: -440px 0
    }

    #header .col-md-5 {
        width: 35%
    }

    #header .col-md-7 {
        width: 65%
    }

    #clinicalresults .results .col-md-8,
    #clinicalresults .results .col-md-4 {
        width: 100%;
        text-align: center
    }

    #clinicalresults .widget_bootstrapcarousel .carousel-inner .col-md-8 {
        min-height: 450px;
        height: auto
    }

    #clinicalresults .widget_bootstrapcarousel .carousel-inner .col-md-4 {
        background-color: #fff
    }
}

@media only screen and (max-width:1023px) {
    .devicesCols .col-md-4 {
        min-height: 800px;
        height: auto;
        width: 49.33%
    }
}

@media only screen and (max-width:1023px) {
    .newsCategoriesTop .container {
        padding-left: 15px;
        padding-right: 15px
    }

    .rightSearch {
        padding-top: 0 !important
    }

    .topmenu {
        display: inline-block;
        color: #fff;
        top: 0
    }

    .fix .navbar-toggle {
        display: none
    }

    .fix .topmenu.btn {
        position: absolute;
        right: 0;
        top: 4px
    }

    .topmenu.btn {
        margin-top: 0
    }

    #header .btn-group,
    #header .col-md-7 nav {
        /* display: none */
    }
    .navcont11{
        display: none;
    }

    #header .smallmenu .socialIconsBottom .btn-group {
        display: inline-block
    }

    #header .smallmenu .socialIconsBottom {
        text-align: Center
    }

    #header .smallmenu .btn-group .selectLang {
        display: block;
        text-align: center;
        padding: 10px 0
    }

    .smallmenu .navbar-nav>li {
        float: none;
        display: block
    }

    .smallmenu .navbar-nav {
        text-align: center;
        float: none
    }

    .modal {
        z-index: 10001
    }

    .small .navbar-default .navbar-nav>li>a {
        color: #fff
    }

    .container {
        padding-left: 50px;
        padding-right: 50px
    }

    .mobileImage .container {
        padding-left: 15px;
        padding-right: 15px
    }

    .topImage.textBlack h1,
    .topImage.textBlack p {
        color: #fff
    }

    .topImage.productHeader.textBlack h1,
    .topImage.productHeader.textBlack p {
        color: #000
    }

    .adjustcol4 .col-md-4.id4,
    .adjustcol4 .col-md-4.id5,
    .adjustcol4 .col-md-4.id6 {
        min-height: 260px
    }

    .articleTeam article {
        width: 49%;
        display: inline-block;
        padding: 20px 10px;
        vertical-align: top
    }

    .newsTop {
        background-color: #c6bac4;
        xbackground-image: none !important;
        color: #fff
    }

    .topImage .hs_submit .actions {
        text-align: center !important
    }

    .topImage.newsTop h1,
    .topImage.newsTop p,
    .topImage.newsTop h4 {
        color: #fff
    }
}

@media only screen and (max-width:1023px) {

    .caroPic .text-right,
    .caroPic {
        text-align: center
    }

    addresstable table td {
        width: 100%;
        display: block
    }

    .picTable td {
        vertical-align: top
    }

    .adjustcol4 .col-md-4 {
        height: auto;
        min-height: 250px
    }

    .wrapProgram {
        width: 49%;
        display: inline-block
    }
   
    fix .navbar-default .navbar-nav>li{
        text-align: left;
    }
    #header .navbar-default .navbar-nav>li>a,
    .fix .navbar-default .navbar-nav>li>a {
        font-size: 13px;
        text-align: center;
        padding: 25px 0;
        
    }

    .productHover {
        min-height: 475px;
        height: auto
    }

    .productHover .viewProd {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        display: inline-block
    }

    .productHover:hover:after {
        border: 0 solid #fff
    }

    .productHover:after {
        border: 0 solid #fff
    }

    .compareProd {
        top: auto;
        bottom: 0;
        z-index: 3;
        position: relative;
        display: inline-block
    }

    .topImage:before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top right, #000, #000);
        -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#1471da, endColorstr=#1C85FB)';
        opacity: .6
    }

    .easyUpgrades {
        position: relative
    }

    .easyUpgrades:before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top right, #000, #000);
        -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#1471da, endColorstr=#1C85FB)';
        opacity: .6
    }

    .productHeader.topImage:before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top right, #000, #000);
        -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#1471da, endColorstr=#1C85FB)';
        opacity: 0
    }

    .xtopImage.textBlack h1,
    .xtopImage.textBlack p {
        color: #fff
    }

    .container {
        width: 100%
    }

    #businessAdvantage .row {
        margin-right: 0;
        margin-left: 0
    }

    .topImage {
        text-align: center
    }

    .footerlinks .col-md-2 {
        width: 24%;
        display: inline-block;
        vertical-align: top
    }

    .footerAccordion {
        color: #e2e2e2
    }

    .xfooterPanel {
        background-color: transparent;
        overflow: auto
    }

    .footerlinks .col-md-4 .text-right {
        text-align: center;
        padding-top: 35px
    }

    .topFooter .col-md-3 {
        width: 30%;
        display: inline-block
    }

    .topFooter .col-md-9 {
        width: 68%;
        display: inline-block
    }

    #devices .nav.nav-tabs>li>a {
        padding: 20px
    }

    .xtreatmentGrid .col-md-4 {
        width: 33%;
        display: inline-block
    }

    .bannerPaddingRight {
        padding-right: 50px;
        padding-left: 50px
    }

    .logoSlice .col-md-5 {
        width: 40%;
        display: inline-block;
        vertical-align: top;
        z-index: 3;
        position: relative
    }

    .lang_he .logoSlice .col-md-5 {
        float: right
    }

    .logoSlice .col-md-7 {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        position: absolute;
        right: 35px;
        z-index: 2
    }

    .logoSlice>.container {
        width: 96%
    }

    .xheaderStack {
        padding: 15px 0
    }

    .socialIconsTop {
        padding-top: 15px;
        padding-bottom: 10px;
       
    }

    .fix .navbar-brand {
        padding-left: 20px
    }

    .fix .navbar-toggle {
        margin-right: 25px
    }

    .navbar-toggle {
        margin-right: 5px
    }

    .navbar-header {
        float: none
    }

    .navbar-left,
    .navbar-right {
        float: none !important
    }

    .navbar-toggle {
        display: block
    }

    .navbar-colla {
        display: none;
        border-top: 0 solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1)
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px
    }

    .navbar-colla.collapse {
       
    }

    .navbar-nav {
        float: none !important;
        margin-top: 20px;
        margin-bottom: 20px
    }

    .navbar-nav>li {
        float: none;
        width: 100%
    }

    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 5px
    }

    .dropdown-menu {
        position: relative;
        width: 100%
    }

    .dropdown-menu li {
        text-align: center
    }

    #header .nav>li.last>a {
        padding-right: 5px
    }

    .collapse.in {
        display: block !important
    }

    .navbar-collapse {
        background-color: #000;
        top: 11px;
        position: relative
    }

    #devices .tab-content {
        padding: 40px
    }

    #devices .nav-tabs>li {
        float: none;
        display: inline-block
    }

    .lang_he #devices .nav-tabs>li {
        float: none
    }

    #devices ul.nav-tabs {
        text-align: center
    }

    #clinicalresults .col-md-8,
    #clinicalresults .col-md-4 {
        width: 100%;
        text-align: center
    }

    .ArticleCol3 {
        width: 49%;
        display: inline-block;
        vertical-align: top;
        padding: 10px 30px
    }

    .MidArticle {
        border-right: 0 solid #6c6c6c
    }

    .location_he-il .MidArticle {
        border-right: 1.5px solid #6c6c6c;
        border-left: 0 solid #6c6c6c
    }

    .ceoslice .article_image img {
        max-width: 100%;
        height: auto
    }

    .ceoslice .article_image {
        width: 35%;
        margin-right: 15px
    }

    .ceoslice .article_titlenolink {
        padding-top: 20px
    }

    .ceoslice .article_html {
        padding-right: 25px
    }

    #header .dropdown-menu>li>a,
    .fix .dropdown-menu>li>a {
        font-size: 17px;
        text-transform: uppercase
    }

    #header .dropdown-menu>li>a:hover,
    .fix .dropdown-menu>li>a:hover {
        background-color: #333
    }

    .subscriptionModel,
    .partnership,
    .businessDev,
    .continuousEducation,
    .practiceEnhancements {
        background-image: none !important;
        padding: 50px 0;
        border-top: 0 solid #dbd7d7
    }

    .warrantyBG {
        background-size: 70%;
        background-repeat: no-repeat;
        padding-bottom: 150px
    }

    .topImage.productHeader.textBlack h1,
    .topImage.productHeader.textBlack p {
        color: #000
    }

    .topImage.productHeader img {
        max-width: 50%;
        height: auto
    }

    .topImage.productHeader .bliss-image img {
        max-width: 100% !important;
        height: auto
    }

    .investmentCols .col-md-4 {
        width: 49%;
        display: inline-block;
        min-height: 250px;
        vertical-align: top
    }
}

@media only screen and (max-width:992px) {

    #advantageColCircles .row.cnt6 div.col-md-6.col-sm-12:nth-child(2),
    #advantageColCircles.advantegeCirclesInsideTab .col-md-6.col-sm-12:nth-child(2) {
        order: -1
    }
}

@media only screen and (min-width:992px) {
    #advantageColCircles .col-md-6 {
        width: 49%
    }
}

@media only screen and (max-width:991px) {
    .hide-md {
        display: none
    }

    #enhancementslice {
        background: 0 0
    }

    #educationslice {
        background: 0 0
    }

    #veroslice {
        background: 0 0
    }

    #advantageColCircles img {
        max-width: 100%;
        height: auto
    }

    .ceoslice .article_titlenolink {
        padding-top: 20px;
        text-align: center;
        display: block
    }

    .ceoslice .article_image {
        width: 100%;
        text-align: center;
        margin-right: 0;
        display: block;
        float: none
    }

    .ceoslice .article_meta_designation,
    .ceoslice .article_title {
        text-align: center;
        margin-top: 10px
    }

    .productCarousel .carousel-control .glyphicon-chevron-left,
    .productCarousel .carousel-control .icon-prev {
        left: 10%;
        margin-left: -10px
    }

    .productCarousel .carousel-control .glyphicon-chevron-right,
    .productCarousel .carousel-control .icon-next {
        right: 10%;
        margin-left: -10px
    }

    .mobileImage {
        display: block
    }

    .mobileImage img {
        width: 100%;
        max-width: 100%;
        height: auto
    }

    .aesthetic {
        background-image: none !important;
        padding: 50px 0
    }

    .modal-body {
        padding: 30px
    }

    .topImage.careersTop:before {
        opacity: 0
    }

    .addressTable table td {
        width: 100%;
        display: block
    }

    .warrantyBG {
        background-size: 70%;
        padding-bottom: 20px
    }

    .subscriptionModel {
        padding-bottom: 0
    }
}

@media only screen and (max-width:767px) {

    .practiceEnhancements,
    .continuousEducation {
        padding: 0
    }

    #educationslice {
        padding: 40px 0 10px
    }

    #veroslice {
        padding: 40px 0 10px
    }

    #enhancementslice {
        padding: 0
    }

    .tribella-image-container {
        width: 100%
    }

    .center-width-sm-70 {
        width: 100%;
        margin-left: auto;
        margin-right: auto
    }

    .warrantyBG {
        background-size: 70%
    }

    .topImage h1 {
        font-size: 36px;
        line-height: 1em
    }

    h1,
    .title-h1 {
        font-size: 38px
    }

    h2,
    .title-h2 {
        font-size: 28px
    }

    h3,
    .title-h3,
    .newsStackLeft .widget_article h2 {
        font-size: 18px
    }

    h4,
    .title-h4 {
        font-size: 15px
    }

    h5,
    .title-h5 {
        font-size: 17px
    }

    h6,
    .title-h6 {
        font-size: 15px
    }

    body {
        font-size: 14px;
        line-height: 22px
    }

    .logoSlice>.container {
        width: 100%;
        padding: 0 20px
    }

    .logoSlice img {
        max-width: 160px;
        height: auto
    }

    .logoSlice .col-md-7 {
        right: 0
    }

    .adjustcol4 .col-md-4.id4,
    .adjustcol4 .col-md-4.id5,
    .adjustcol4 .col-md-4.id6 {
        min-height: 110px
    }

    .adjustcol4 .col-md-4 {
        height: auto;
        min-height: 110px
    }

    .absCircle {
        display: none
    }

    .easyUpgrades {
        padding: 50px 0;
        background-position: center
    }

    .tickUL ul {
        padding: 35px 25px 5px
    }

    #devices .padding75 {
        padding: 25px 0
    }

    .articleTeam article {
        width: 100%;
        display: inline-block;
        padding: 20px 10px;
        vertical-align: top
    }

    .ArticleCol3 {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        padding: 10px 30px
    }

    .MidArticle {
        border-left: 0 solid #6c6c6c
    }

    .careerList .btn {
        background-color: transparent;
        border: 0;
        padding: 5px 0;
        margin-top: 0
    }

    .careerList .article_excerpt {
        height: auto
    }

    .subscriptionModel,
    .partnership,
    .businessDev {
        padding: 40px 0 10px
    }

    .topImage.careersTop {
        background-size: 150%;
        background-repeat: no-repeat
    }

    .submitResume form div.field {
        width: 100%;
        display: inline-block;
        vertical-align: top
    }

    #devices .nav-tabs>li {
        float: none;
        display: block
    }

    #devices .tab-content {
        padding: 20px 0
    }

    #clinicalresults .carousel-control.left,
    #clinicalresults .carousel-control.right {
        display: none
    }

    .results .carousel-inner .item {
        padding: 0
    }

    .otherProducts .pImage {
        max-width: 50%;
        height: auto
    }

    .otherProducts {
        width: 100%
    }

    .videoTreatment .container iframe {
        width: 100%;
        height: 320px
    }

    .topFooter .col-md-9 {
        width: 100%;
        display: inline-block
    }

    .topFooter .id2.last {
        padding-top: 10px
    }

    .careerList .article_detail_full .article_title {
        font-size: 32px
    }

    .picTable td {
        text-align: Center;
        width: 100%;
        display: block
    }

    .topImage.small {
        min-height: 320px
    }

    .warrantyBG.padding175 {
        padding: 20px 70px 10px 0;
        background-size: contain;
        background-repeat: no-repeat
    }

    .wrapProgram {
        width: 100%;
        display: inline-block
    }

    .devicesCols .col-md-4 {
        min-height: 400px;
        height: auto;
        width: 100%
    }

    .warrantyBG {
        padding-right: 60px
    }

    .tab-ProductImage {
        display: block;
        width: 100% !important;
        float: none;
        margin-bottom: 10px;
        text-align: center
    }

    .tab-ProductImage img {
        max-width: 50%;
        height: auto
    }

    .tab-ProductContent {
        padding-left: 0 !important
    }

    #newsStack .container {
        padding-left: 20px;
        padding-right: 20px
    }

    .tab-ProductContent {
        padding-left: 0
    }

    .lang_he .tab-ProductContent {
        padding-right: 0 !important
    }

    .investmentCols .col-md-4 {
        width: 100%
    }

    .container {
        padding-left: 40px;
        padding-right: 40px
    }

    .mobileImage .container {
        padding: 0 15px
    }

    .topImage .container {
        padding-left: 20px;
        padding-right: 20px
    }

    .topImage .bannerPaddingRight {
        padding-right: 10px;
        padding-left: 10px
    }

    .devicesCols .container {
        padding-left: 2px;
        padding-right: 2px
    }

    .prodImage img {
        max-width: 150px;
        height: auto
    }

    .addressTable table td {
        padding-bottom: 10px
    }

    .sectionHeader h3,
    .sectionHeader h4,
    .regions h3,
    .regions .title-h3 {
        text-align: center
    }

    .regions td {
        text-align: center;
        vertical-align: top
    }

    .regions table td img {
        margin: 10px auto;
        display: block
    }

    .topFooter .col-md-3,
    .topFooter .col-md-9,
    .topFooter .col-md-9 .text-right {
        width: 100%;
        text-align: Center
    }
}

@media only screen and (max-width:700px) {
    .FooterAccnew {
        xoverflow: hidden
    }

    .footerlinks .col-md-2 {
        width: 100%;
        display: inline-block;
        vertical-align: top
    }

    .footerAccordion {
        color: #e2e2e2
    }

    .footerPanel {
        background-color: transparent;
        overflow: auto
    }

    #devices ul.nav-tabs {
        padding-right: 0
    }
}

@media only screen and (max-width:670px) {
    .location_he-il #header .col-md-5 {
        width: 50%
    }

    .centerMobile {
        text-align: center
    }

    .videoTreatment .container {
        width: 100%;
        padding: 0
    }

    .topImage.testimonials,
    .topImage.contact {
        background-position: center
    }

    .padding175 {
        padding: 50px 0
    }

    .productHover {
        padding: 10px 30px
    }

    .testimonialCaro .carousel-control .glyphicon-chevron-left,
    .testimonialCaro .carousel-control .icon-prev {
        right: 0;
        margin-left: 0
    }

    .testimonialCaro .carousel-control .glyphicon-chevron-right,
    .testimonialCaro .carousel-control .icon-next {
        left: 0;
        margin-left: 0
    }

    #devices .nav-tabs>li {
        border-bottom: 1px solid #ccc
    }

    .productCarousel .carousel-inner .item {
        padding: 5px 0
    }

    .caroPic {
        padding: 10px 0
    }

    .productCarousel .carousel-control {
        top: -35px;
        bottom: auto
    }

    .customImgTreatment img {
        position: relative;
        right: 0;
        top: 0;
        max-width: 500%
    }

    .customImgTreatment.padding75 {
        padding: 35px 0
    }

    .topImage.productHeader .customImgTreatment img {
        max-width: 85%;
        height: auto
    }

    #clinicalresults .widget_bootstrapcarousel .carousel-inner .col-md-8 {
        padding: 20px 10px
    }

    .results .container {
        padding-left: 15px;
        padding-right: 15px
    }

    .regions table td img {
        max-width: 30px;
        height: auto
    }

    .regions table td a {
        font-size: 13px;
        line-height: 21px
    }

    .regions .container {
        padding-left: 20px;
        padding-right: 20px
    }

    .newsLanding article .article_location {
        font-size: 16px
    }

    .callOut {
        padding: 20px !important
    }
}

@media only screen and (max-width:500px) {

    .contactForm .input input[type=text],
    .contactForm .input input[type=tel],
    .contactForm .input input[type=email] {
        max-width: 100% !important;
        width: 100% !important
    }

    .article_detail_full .article_date_end {
        display: block;
        padding-left: 60px
    }
}

@media only screen and (max-width:420px) {
    .warrantyBG {
        background-image: url(./images/warrantly_bg_light.jpg);
        background-size: contain
    }

    .newsCategoriesTop li.list-group-item {
        width: 100%;
        border-bottom: 1px solid #000;
        text-align: center;
        padding: 10px
    }

    .location_he-il #header .col-md-5 {
        width: 70%
    }
}

@media only screen and (max-width:413px) {
    .logoSlice .col-md-5 {
        padding-left: 10px
    }

    .logoSlice .col-md-7 {
        padding-right: 10px
    }

    .fix .container {
        padding-left: 10px;
        padding-right: 20px
    }

    .btn {
        padding: 15px
    }
}

@media screen and (max-width:1100px) {
    .navbar {
        display: none
    }

    .logoSlice {
        /* padding: 25px 0 */
    }

    .topmenu {
        display: inline-block;
        top: 7px;
        margin-top: 0
    }

    #smallmenu .region {
        display: block;
        right: 20%;
        left: 20%;
        width: 88px;
        margin: auto
    }

    #header .smallmenu .btn-group {
        display: block;
        text-align: center;
        padding: 10px 0
    }

    #header .smallmenu .socialIconsBottom .btn-group {
        display: inline-block
    }

    .smallmenu button {
        right: 0;
        top: 35px;
        position: relative;
        text-align: right;
        display: block;
        opacity: .9;
        position: relative;
        text-shadow: none;
        color: #fff;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: .5px;
        z-index: 2
    }

    .smallmenu button:hover {
        color: #fff
    }

    .smallmenu .close:hover {
        color: #fff
    }

    #header .smallmenu .btn-group .selectLang {
        display: block;
        text-align: center;
        padding: 10px 0
    }

    #header .smallmenu .socialIconsBottom {
        text-align: center
    }

    #header .btn-group,
    #header .col-md-7 nav {
        display: none
    }

    .region {
        display: none
    }

    .lang_en .socialIconsTop,
    .location_fr-ca .socialIconsTop,
    .location_ko .socialIconsTop,
    .socialIconsTop {
        padding-top: 1%;
    }

    .lang_he .socialIconsTop {
        padding-top: 15px
    }
}

html:lang(fr-ca) .megamenu-wrapper {
    transform: translate(-40%, 0)
}