body{
   
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    margin: 0;
    padding: 0;
    border-color: #e5e5e5;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif!important;
     max-width: 100%;
     overflow-x: hidden;
    
}
._contactbgimge{    padding: 190px 0px 80px;
    position: relative;
    z-index: 89;
    line-height: 1;
    background-image: "";
    background-repeat: repeat;
    background-position: center;

}
.contactcon{
width: 100%;

}
.contactcol1{
    float: left;
    position: relative;
    min-height: 1px;

}
.contactmainpcol{
    float: left;
    position: relative;
    min-height: 1px;
    width: 58.666667%;
}
.contactsection{
    margin-bottom: 60px;

}
.content-itemfor{
    margin-bottom: 60px;
    margin: 0px 15px 20px;
    min-height: 1px;

}
.conatctspace{
    margin-top: -22px;
}
.conatctformh5{
    font-weight: bold;
    color: #454545;
    font-size: 16px;
}
.gdlr-space{
    margin-top: 25px;
}
._formfield{
    margin-bottom: 20px;
    margin:0px 0px 20px;
padding:0px
}
._formfieldlabel{
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    display: inline;
}
.contformspan{
    display: flex;
    padding-right: 46px;
    margin-top: 7px;

}
.contformspinput{
    padding: 16px 19px;
    width: 100%;
    font-size: 13px;
    color: #b5b5b5;
    background-color: #f7f7f7
}
.contformstextarea{
    padding: 16px 19px;
    margin: 0;
    width: 100%;
    font-size: 13px;
    color: #b5b5b5;
    background-color: #f7f7f7;
    
    text-decoration:
    none solid rgb(181, 181, 181);
    border: 0px;
    outline: none;
    
    max-width: 100%;
  

}
.submitcontactppp{
    margin-bottom: 20px;
}
.submitcontactpppinput{
    padding: 10px 20px;
    letter-spacing: 0;
    text-transform: none;
    background-color: #0201fc;
    font-size: 16px;
    text-align: center;
    line-height: 33px;
    color: #ffffff;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    width: 94%;
    margin-top: 0% !important;
    margin-left: 6px;
}
.wpcf7-spinner{
    visibility: hidden;
    display: inline-block;
    background-color: #23282d;

    opacity: 0.75;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 100%;
    padding: 0;
    margin: 0 24px;
    position: relative;
}
.rightcontsidecontact{
    padding-top: 60px;
    
    float: left;
    position: relative;
    min-height: 1px;
}
.start_rightcontent{
    margin: 0px 0px 20px;
    min-height: 1px;
}
.gdlr-widget-title, .contactinformationtitle, .socialmediaiciontih3 {
    color: #383838;
    display: inline-block;
    padding-right: 4px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2.2px;
    /* madeit */
    margin-right: 15px;
}
.contacttextwidget{
    
    
}
.contactinformationdetails {
    color: #808080;
    margin-bottom: 20px;
    font-size: 14px;
}
.contacttextwidgetp{
    margin-bottom: 20px;
    font-size: 14px;
    color: #808080;
    font-weight: 400;
    line-height:23.8px;
    padding: 0;
    letter-spacing: normal;
    word-spacing: 0px;

}
.contactinformation{
    margin-bottom: 60px;

}

.contactinformationpppp{
    color: #808080;
    margin-bottom: 20px;
    font-size: 14px
}
.contacticon{
color: #444444;
font-size: 16px;
display: inline-block;
line-height:23.8px;
margin-right: 10px;
padding:0px;
font-weight:400;
}
.socialmediaicionaaa{
    color: #444444;
    font-size: 28px;
    display: inline-block;
    line-height:23.8px;
    margin-right: 10px;
    padding:0px;
    font-weight:400;
    }
.socialmedia-icons {
    margin-bottom: 60px;
}
.socialmediaicionti{
  
    margin: 0px 15px 20px;
    min-height: 1px;
}

.socialmediaiciontiaaa{
    text-decoration: none;
        color: #639edb;
}
.contactsection-3{
    font-size: 14px;
}
.contactbelowbgimg{
    background-image: url(./images/contactusss.png);
    padding-top: 100px;
    padding-bottom: 50px;
    background-position: center -60.88px;
    overflow: hidden;
    background-position: center 0px;
    background-repeat: repeat;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}
.contactbelowcontainer{
    width: 1140px;
    padding: 0px;
    margin: 0px auto;
}
 .contactbeowcol{
    float: left;
    position: relative;
    min-height: 1px;
    width: 33.333%;
    
 }
.contactbeowcontent{
        /* font-size: 14px;
        opacity: 1;
        padding-top: 0; 
        margin-bottom: 0;
        color: #808080;  */
        padding-top: 50px 30px 20px;
    color: #929292;
    background-color: #f5f5f5;
    text-align: center;
    padding: 40px 30px 20px;
    position: relative;
    margin: 0px 15px 20px;
    min-height: 1px;
}
.gdlr-item gdlr-box-with-icon-item pos-top type-circle{
    padding-top: 50px;
    text-align: center;
    padding: 40px 30px 20px;
    position: relative;
    margin: 0px 15px 20px;
    min-height: 1px;
    
}
.box-with-circle-icon{
    background-color: #eb639c;
   
    top: -30px;
    left: 50%;
    margin-left: -30px;
    padding: 18px;
    display: inline-block;
    position: absolute;
    -moz-border-radius: 30px;
    
    border-radius: 30px;
}
.box-withicon{
    color: #ffffff;
    font-size: 22px;
    margin-right: 0;
    display: inline-block;
    text-rendering: auto;
}
.box-with-icon-title{
    color: #585858 !important;
    font-size: 20px;
    margin-bottom: 20px;
    display: block;
}
.box-with-icon-caption{
    color:  #929292;
    font-size: 20px;
    margin-bottom: 20px;
    display: block;
}
.box-with-icon-caption p{
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 16px;
    line-height: 27.2px;
    font-weight: 400;
}
.contactfooter{
    background-color: #ffffff;
    color: #bfbfbf;
    position: relative;
    background-repeat: no-repeat;
    background-position: right top;

}
.contactfootcont{
    margin:0px 189.6px;
    padding:60px 0px 0px;
    line-height:23.8px;
    
word-spacing:
0px;
}
.contfootcol{
    border-color: #363636;
    margin: 0px 15px 20px;
    min-height: 1px;
}
.contfootcntent1{

}
.contfootcntent1h2{
    text-align: center;
     padding-bottom:15px; 
     font-size: 48px;
     color: #454545;
}
.contfootcntent1span{
    color: #00b5ca;
}