
    body {
        font-size: 16px;
        background-color: #f5f5f5;
        margin: 0;
        padding: 0;
        line-height: 26px;
        min-height: 100vh;
        font-family: 'Raleway', sans-serif!important;
         max-width: 100%;
         overflow-x: hidden;
        
    }

.afusleeindiv{
    position: fixed;
    width: 100%;
    top: -115px;
    transition: top .5s;
    -webkit-transition: top .5s;
    background-color: #fff;
    z-index: 9998;
    background: #515151;
}
.aftmaoincontentbox{
    margin-bottom: -16px;
    margin-top: 10rem;
    background-color: black !important;
}
.aftitem1{
    background-color: white;
}
.contents_guid_{

}
.aftfirstcontainer{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.aft1infodiv{
    margin-right: -15px;
    margin-left: -15px;
}
.aft1infodivcol{
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.aft1infconttttt{
    padding: 50px 0;
}
.aft1infco111111{

}
.aft1infppppp{
    color: #000;
    margin: 0 0 10px
}
.aft1img{
    width:354px; 
    height:176px;
    padding-bottom: 35px;
}
.aft1infh1111{
    color: #000;
    font-size: 64px;
    line-height: 1em;
    letter-spacing: -1px;
    padding-bottom: 15px;
    font-weight: 300;
}
.aft1infh2222{
    margin-top: 0;
    padding-top: 0;
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    
    margin-bottom: 10px
}
.spaekbutton{
    opacity: 1;
    cursor: pointer;font-weight: 800;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    /* background-color: #555556; */
    border: 1px solid #fff;
    border-radius: 22px;
    padding: 15px 25px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 12px;
    background-color: #0a71f0;
    border: 1px solid #9b9b9b;
    text-decoration: none;
}
.aftbuttfppppp{
    margin: 30px 0 10px;
}
.spaekbutton:hover {
    background-color:
     RGB(15, 86, 153);
     color: #fff;
     text-decoration: underline;
}
.aft1infodivimgcol2{
    float: right;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}
.aft1infodivimg{}
.aft1infodivimgpppp{
    margin: 40px 0 10px;
}
.aft1infodivimgsrc{
    width:360px;
     height:641.67x;
     border: 0;
     
}
.onluppp{
    margin: 0 0 10px;
}
.alfsubnav{
    background-color: black;
}
.atfsubNavslice{

}
.atfcontnsubnav{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}
.atfcontnsubnavrow{
    margin-right: -15px;
    margin-left: -15px;
}
.atfcontnsubnavrcol1{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 16.66%;
}
.atfsubnavcontent{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}
.atfsubnavcontentaaaaa{
    color: #fff;
    margin: 17px 10px;
    display: inline-block;
    font-weight: 600;
    background: transparent;
    text-decoration: none;
}
.atfsubnavcontentaaaaa:hover{
    text-decoration: underline;
    color: #fff;
}
.atfcontnsubnavrcol2222{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}
.speakbuttonnavbar{
    background-color: #fff;
   
    color: #666467;  
    margin: 8px 0;
    font-size: 12px;
    padding: 15px 25px;
    border-radius: 22px;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    text-decoration: none;
    
}
.speakbuttonnavbar:hover{
    background-color: #666467;
color: #fff;
text-decoration: underline;
}
.atfcontet3div{
    background-color: #fafafa;
    padding: 75px 0;
}
.atfcontet3contain{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.atfcontet3row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

}
.atfcontet3col{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.atfcontenttttt{

}
.atfcontenh222{
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.atfcontet3col{
  
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.atfcontet3content2{
    padding:50px 0;
}
.atfcontet3contentpppp{
    
    margin: 0 0 10px;

}
.atfcontet3contentimgg{
    width:564px;
     height:318px;
     vertical-align: middle;
     border: 0;

}
.atfcontet3h4444{
    margin-top: 0;
    padding-top: 0;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 500;
}
.atfcontet3h4ppp{
    font-weight: 700;
}
.atfcontet3ppp{
    margin: 0 0 10px
}
.aftkeyfeaturescontaine{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}
.aftfeaturesslice{
   
    color: #fff;
}
.aftkeyfeaturow{
    margin-right: -15px;
    margin-left: -15px;

}
.aftkeyfeatcol1{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.ftkeyfeatpppp{
    margin: 0 0 10px;
}
.aftkeyfeaimg{
    width:457.5px;
     height:786.39px;
     border: 0;
     vertical-align: middle;
     background-color: black;
}
.aftkeyfeatcol222{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10%;
}
.aftkeyfeatcol2divv{
    padding: 75px 0;
}
.aftkeyfeatcol2h222{
    margin-top: 0;
    padding-top: 0;
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    padding-bottom: 10px;
    margin-bottom: 10px
}
.aftkeyfeatcol2pppppp{
    margin: 0 0 10px;
}
.aftkeyfeatdropdown1up{
    margin-bottom: 50px
}
.aftfeatures-collapse{
    margin-bottom: 20px;
}
.aftfeaturescollapsedown{
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: unset;
    margin-bottom: 0;
    border-color: #ddd;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-sizing: border-box;
}
.aftdroppanelheading{
    color: #fff;
    background-color: transparent;
    border-color: #ddd;
    padding: 20px 15px;
    border-bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}
.aftdroppaneltitle{
    background-image: url(./images/dropdown.webp);
    background-position: center right;
    background-repeat: no-repeat;
    position: relative;
    display: block;
    background-size: 25px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
    padding-top: 0;
    line-height: 24px;

}
.aftdroppanebtton{
    display: flex;
    align-items: baseline;
    min-height: 45px;
    color: inherit;
    background-color: transparent;
    text-decoration: none;
text-decoration: none;
}
.aftdroppanebtton:hover{
    color: white;
    text-decoration: underline;
}
.aftdroppanspann{
   
    padding-bottom: 6px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 29px;
    border: 1px solid #fff;
    margin-right: 15px;
    display: flex;
    width: 43px;
    height: 43px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #666467;
    
}
.aftdroppanspann:hover{
    text-decoration: none;
}
.aftdroppanelbodyy{
    border-top: none;
    margin-left: calc(15px + 43px);
    padding: 15px;

}
.aftdroppanelbodypppp{
    margin: 0 0 10px;

}
.videoTreatment{

}
.atfmodalitiesdiv{
    background-image: url(./images/produ1bglower.jpg);
    background-size: cover !important;
    padding: 100px 0;
    background-color: #1d2f35;
}
.atfmodalitiessection{
    color:white;
}
.atfmodalitiessectcontai{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    color:white;

}
.atfmodalitiessectcol1{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}
.atfmodalitiessectpadding5{
    padding: 50px 0;
}
.atfmodalitiessech22{
    margin-top: 0;
    padding-top: 0;
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-align: center;

}
._produvctname{
    text-align: center;
    color: #00b5ca;
    font-weight: 700;
    font-size: 53px;
    margin: 27px;
}

.atfmodalitiesseppppp{
    margin: 0 0 10px;
    text-align: center;
}
.atfmodaliessectcol2222{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}
.atfmodaliessectcontain2222{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.prodyimhhhhmdkd{
    margin-left: -14px;
        
}
@media (min-width: 1200px){
   
.aftfirstcontainer {
    width: 1170px;
        
    }
    .atfcontnsubnav{
        width: 1170px;
    }
    .atfmodalitiessectcontai{
        width: 1170px;
    }
}

    @media (min-width: 992px){
        
.aftfirstcontainer{
    width: 970px;
}
.atfcontnsubnav{
    width: 970px;
}
.atfcontnsubnavrcol1{
    margin-left: 16.66666667;
    float: left;
}
.atfcontnsubnavrcol2222{
   float: left; 
}
.atfcontet3contain{
    width:970px ;
}
.atfcontet3col{
    width: 100%;
    float: left;
}
.atfcontet3col{
    width: 50%;
    float: left;
}
.aftkeyfeatcol1{
    width: 41.66666667%;
    float: left;
}
.aftkeyfeatcol222{
    width: 58.33333333%;
    float: left;
    padding-top: 10%;
}
.aftkeyfeatcol2divv{
    padding-right: 50px;
    padding-left: 50px;
}
.aftdroppanelheading{
    padding-right: 50px;
    padding-left: 50px;
}
.atfmodalitiessectcontai{
    width: 970px
}
.atfmodalitiessectcol1{
    width: 100%;
    float: left;

}
.atfmodaliessectcol2222{
    width: 100%;
    float: left;
}
}
@media (min-width: 768px){
.aftfirstcontainer {
    width: 750px;
}
.atfcontnsubnav{
    width: 750px;
}
.atfcontet3contain{
    width: 750px;
}
.atfmodalitiessectcontai{
    width: 750px;

}
}
@media (max-width: 1200px){
    .atfcontet3contentimgg{
        width:21rem;
         
         
    }
    .aft1infodivimgsrc{
        width: 18rem;
    }
}
@media only screen and (max-width: 767px) {
    .atfcontnsubnavrcol1{

    }
    .atfsubnavcontent{

    }
    .aft1infh1111{
        font-size: 39px;
       }
       .ft1infh1ppp{
        font-size: 23px;
       }
       .atfcontnsubnavrcol1{
        margin-left: 0;
       }
       .prodyimhhhhmdkd{

       }
       .prodyimhhhhmdkd{
        margin-left: 0;
        width: 306px;
       }

}
@media only screen and (min-width: 768px) and (max-width: 991px) {
   .atfcontnsubnavrcol1{
    margin-left: 0;
   }
   .atfsubnavcontent{
    
   }
   .aft1infh1111{
    font-size: 48px;
   }
   .ft1infh1ppp{
    font-size: 21px;
   }
   .prodyimhhhhmdkd{
    margin-left: 0;
   }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {

}
@media only screen and (min-width: 1200px) and (max-width: 1279px){

}
@media screen and (min-width: 1280px){

}