body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    line-height: 26px;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif!important;
     max-width: 100%;
     overflow-x: hidden;
    
}

#header {
    z-index: 10
}

body.shift #header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1051;
}

#contentsbox {
    z-index: 5
}

#contents {
    overflow-x: hidden !;
}

body,
html {
    font-family: raleway, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #000
}

body:lang(he) {
    font-family: assistant, sans-serif
}

body:lang(vi) {
    font-family: arial, sans-serif
}

h1,
.title-h1 {
    font-size: 72px;
    font-weight: 300;
    line-height: 1.1em;
    letter-spacing: -1px;
    padding-bottom: 15px
}

h2,
.title-h2 {
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px
}

h3,
.title-h3,
.newsStackLeft .widget_article h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 10px;
    font-weight: 500
}

h4,
.title-h4 {
    font-size: 18px;
    line-height: 24px
}

h5,
.title-h5 {
    font-family: montserrat, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px
}

h6,
.title-h6 {
    font-family: montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 10px
}

h2.paragraph {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 10px;
    padding-bottom: 0
}

.h3clone {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 10px
}

.captionTag {
    font-size: 14px;
    line-height: 18px;
    color: #4a4a4a
}

.productTag {
    font-size: 12px;
    line-height: 14px;
    color: #4a4a4a
}

a {
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -ms-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out;
    color: #0a71f0
}

a:focus,
button:focus,
input:focus {
    outline: none !important;
    color: #0a71f0
}

.padding-left-25 {
    padding-left: 25px
}

.region {
    display: block;
    width: auto;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 8px;
    top: -10px;
    height: 14px
}

.region a {
    padding: 1px 0 0 30px !important;
    display: block;
    font-size: 12px !important;
    letter-spacing: 1px !important
}

.btn {
    font-size: 10px;
    font-weight: 800;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    background-color: #555556;
    border: 1px solid #fff;
    border-radius: 22px;
    padding: 15px 25px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 20px;
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -ms-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out
}

.lang_he .btn {
    font-size: 12px
}

.btn:hover {
    color: #e2e2e2;
    opacity: .7;
    background-color: #555556
}

.btn-white {
    background-color: #fff;
    border: 1px solid #666467;
    color: #666467
}

.btn-white:hover {
    color: #000;
    opacity: 1;
    background-color: #ccc
}

.btn-light {
    background-color: #f5f4f4;
    border: 1px solid #666467;
    color: #666467
}

.btn-light:hover {
    color: #000;
    opacity: 1;
    background-color: #ccc
}

.btn-transparent {
    background-color: transparent;
    border: 1px solid #3487f2;
    color: #3487f2
}

.btn-transparent.white {
    color: #fff
}

.btn-transparent:hover {}

.btn-blue {
    background-color: #0a71f0;
    border: 1px solid #9b9b9b;
    color: #fff
}

.modal button.btn-blue {
    background-color: #0a71f0;
    border: 1px solid #9b9b9b;
    color: #fff;
    position: relative
}

.btn-lblue {
    background-color: #1ab2fb;
    border: 1px solid #1ab2fb;
    color: #fff
}

.btn-blue:hover {
    color: #fff;
    opacity: 1;
    background-color: #0f5699
}

#subheader.fix {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    z-index: 99999;
    padding: 0;
    border-bottom: 1px solid #6d9d89;
    height: 70px
}

.fix {
    position: fixed;
    width: 100%;
    top: -115px;
    transition: top .5s;
    -webkit-transition: top .5s;
    background-color: #fff;
    z-index: 9998;
    background: #515151
}

.fix.affix {
    position: fixed;
    top: 0;
    width: 100%;
    opacity: .95;
    z-index: 1049;
    transition: top .5s;
    -webkit-transition: top .5s;
    background-image: url(./images/menu_bg.png);
    background: #515151
}

.fix .navbar-brand {
    padding: 0
}

.fix .navbar-nav {
    padding-top: 10px
}

.modal-dialog {
    width: 90%;
    max-width: 800px;
    margin: 10% auto
}

a:focus,
button:focus {
    outline: none
}

.modal button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: 0 0
}

.cookie-button {
    border: 0;
    background: 0 0
}

.logoSlice {
    background-image: url(./images/menu_bg.png);
    padding: -10px 0
}

#logoslice p {
    margin-bottom: 0
}

.socialIconsTop a {
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 2px;
    color: #cccbcd;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.socialIconsTop a:hover {
    color: #fff;
    text-decoration: none
}

.socialIconsTop .btn-group-padded {
    margin-right: 25px
}

.location_he-il .socialIconsTop .btn-group-padded {
    margin-left: 25px
}

.location_he-il .socialIconsTop .btn-group-padded:first-child {
    margin-right: 10px;
    margin-left: 0
}

.location_he-il .navbar-nav {
    padding-right: 0
}

.location_fr-ca .socialIconsTop .btn-group-padded {
    margin-right: 17px
}

.location_fr-ca #header .col-md-7 {
    width: 73%
}

.location_fr-ca #header .col-md-5 {
    width: 27%
}

.location_fr-fr .socialIconsTop .btn-group-padded {
    margin-right: 17px
}

.location_fr-fr #header .col-md-7 {
    width: 73%
}

.location_fr-fr #header .col-md-5 {
    width: 27%
}

.lang_it #header .col-md-5 {
    width: 26%
}

.lang_it #header .col-md-7 {
    width: 74%
}

.lang_de #header .col-md-5 {
    width: 25%
}

.lang_de #header .col-md-7 {
    width: 75%
}


.location_he-il #header .col-md-5 {
    float: left
}

.socialIconsTop {
    text-align: right !important;
    padding-top: 25px;
    padding-bottom: 20px;
    position: relative
}
/* .subjkkbutton{
    padding-left: 27rem;
} */

.socialIconsTop img {
    margin-left: 2px;
    margin-right: 2px
}

.socialIconsTop .btn-group-padded img {
    margin-right: 8px
}

.navbar-default {
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 1px;
    min-height: 30px
}

.navbar-default .container {
    width: 100%;
    padding: 0;
    
}

.navbar-collapse {
    padding: 0
}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-nav>li>a:focus {
    font-size: 16px;
    padding: 16px 40px 26px 0px;
    margin: 0px;
    color: #fff;
    font-style: normal;
    color: #fff !important;
    text-transform: uppercase
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    color: #fff;
    background-color: transparent
}

.navbar-nav {
    text-align: right;
    float: right
}

.navbar-nav>li {
    float: none;
    display: inline-block
}

.footerSlice .navbar-nav>li {
    display: block
}

.nav>li>a {
    padding: 17px 0 17px 15px
}

#header .nav>li.last>a {
    padding: 5px 0 10px 10px
}

.nav-tabs>li>a {
    padding: 20px 50px
}

.navbar-default .navbar-nav>li>a:hover {
    color: #fff
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border: 0
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #fff;
    background-color: transparent !important
}

#header .dropdown-menu,
.fix .dropdown-menu {
    background-color: transparent;
    background-image: url(./images/menu_bg.png);
    margin-top: 2px;
    min-width: 160px;
    padding: 25px 0;
    background-color: rgba(0, 0, 0, .5)
}

#header .dropdown-menu li,
.fix .dropdown-menu li {
    list-style: none
}

#header .dropdown-menu li>a,
.fix .dropdown-menu li>a {
    letter-spacing: 0;
    display: block;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.42857143;
    text-decoration: none;
    white-space: nowrap;
    padding: 10px 25px
}

#header .dropdown-menu li>a:hover,
.fix .dropdown-menu li>a:hover,
#header .dropdown-menu li>a:focus,
.fix .dropdown-menu li>a:focus {
    background-color: #333;
    text-decoration: none;
    color: #fff
}

#header .dropdown-menu li.active>a,
.fix .dropdown-menu li.active>a {
    background-color: #0a71f0
}

#header .dropdown-menu li>ul {
    padding-left: 0
}

#header .dropdown-menu .nearest {
    background-position: 20px center;
    padding-left: 20px
}

.footerStack {
    background-color: #4a4a4a;
    padding: 0 0 30px;
    color: #fff;
    font-size: 11px;
    line-height: 15px;
    color: #e5e4e5
}

.footerStack a {
    color: #e5e4e5
}

.footerStack a:hover {
    color: #fff
}

body.customHeader #header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5
}

.topImage {
    transition: padding-top .3s ease;
    height: auto;
    min-height: 750px;
    background-size: cover;
    background-position: top right;
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center
}

.topImage section {
    width: 100%
}

.topImage.contactBanner {
    padding-top: 300px
}

@media only screen and (max-width:1400px) {
    .topImage.contactBanner {
        padding-top: 300px
    }

    .topImage.contactBanner .col-md-6 {
        width: 100%;
        text-align: center
    }
}

@media only screen and (max-width:480px) {
    .topImage.contactBanner {
        padding-top: inherit
    }
}

@media only screen and (max-width:1199px) {
    .topImage {
        min-height: 850px;
        padding: 0
    }
}

@media screen and (max-width:1023px) {
    .topImage {
        min-height: 800px;
        padding: 0
    }
}

@media screen and (max-width:650px) {
    .topImage {
        min-height: 100vh
    }
}

@media screen and (max-width:350px) {
    .topImage {
        min-height: 800px
    }
}

.location_fr-ca .topImage,
.location_ko .topImage,
.location_en-ca .topImage,
.location_en .topImage,
.location_en-us .topImage,
.location_en-gl .topImage {}

.location_fr-ca .productHeader,
.location_ko .productHeader,
.location_en-ca .productHeader,
.location_en .productHeader,
.location_en-gl .productHeader .location_en-us .productHeader {
    padding: 25px 0 60px
}

.location_fr-ca .logoSlice .region,
.lang_ko .logoSlice .region,
.lang_en .logoSlice .region {
    display: none
}

.topImage.small {
    min-height: 620px
}

.small {
    font-size: 12px
}

.topImage .btn {
    opacity: .8
}

.topImage h1,
.topImage p {
    color: #fff
}

.topImage.textBlack h1,
.topImage.textBlack p {
    color: #000
}

.topImage h1 {
    font-size: 64px;
    line-height: 1em
}

.bannerPaddingRight {
    padding-right: 111px
}

.topFooter .container {
    border-bottom: 1px solid #777;
    padding-bottom: 20px;
    margin-bottom: 20px
}

.topFooter .id2.last {
    padding-top: 10px
}

.copyright {
    border-top: 1px solid #777;
    padding-top: 20px;
    margin-top: 20px
}

.footerlinks {
    padding: 15px 0 45px
}

.footerlinks .btn {
    margin-top: 0
}

.footerSlice .nav a {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.5em;
    padding-bottom: 6px;
    padding-top: 1px
}

.footerSlice .footermenutitle {
    font-size: 11px;
    font-weight: 700;
    line-height: 1.5em;
    padding-bottom: 6px;
    padding-top: 1px;
    padding-left: 10px
}

.footerSlice li.on a {
    font-weight: 700
}

.lang_he .footerSlice ul.lev1 {
    -webkit-padding-start: 0;
    padding-right: 0
}

.lang_he .footerSlice .nav>li>a {
    padding-right: 0
}

.footerSlice .nav li:hover,
.footerSlice .nav a:hover {
    background-color: transparent
}

#innovasium a {
    color: #fff;
    margin: 35px 0 10px
}

.moveStackUp {
    top: -100px;
    position: relative;
    z-index: 999
}

.padding100 {
    padding-top: 50px
}

.productCarousel {
    padding: 50px 0;
    background-color: #f4f4f4
}

.caroPic {
    padding: 40px 0
}

.productCarousel .carousel-control.left,
.productCarousel .carousel-control.right {
    background-image: none !important
}

.glyphicon-chevron-left::before {
    content: url(./images/light-grey-chev.jpg)
}

.glyphicon-chevron-right::before {
    /* content: url(./images/dark-grey-chev.jpg) */
}

.productCarousel .carousel-control {
    top: auto;
    bottom: -15px
}

.productCarousel .left.carousel-control {
    width: 50%;
    text-align: right
}

.productCarousel .right.carousel-control {
    width: 50%;
    text-align: left
}

.productCarousel .carousel-control .glyphicon-chevron-left,
.productCarousel .carousel-control .icon-prev {
    left: 95%;
    margin-left: -10px
}

.productCarousel .carousel-control .glyphicon-chevron-right,
.productCarousel .carousel-control .icon-next {
    right: 95%;
    margin-left: -10px
}

.contactForm .input input[type=text],
.contactForm .input input[type=tel],
.contactForm .input input[type=email] {
    max-width: 490px !important
}

.contactForm fieldset.form-columns-2 .hs-form-field {
    width: 492px !important
}

.hideThis {
    display: none
}

.faq .panel-default>.panel-heading {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    border: 0;
    border-bottom: 1px solid #999;
    margin-bottom: 20px
}

.faq .panel-default>.panel-heading a {
    font-size: 20px;
    line-height: 24px
}

.faq .panel-default {
    border-color: #fff;
    border: 0
}

.fullWidthTabs .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.fullWidthTabs .container .row {
    margin-left: 0;
    margin-right: 0
}

.fullWidthTabs .container .col-md-12 {
    padding-left: 0;
    padding-right: 0
}

.blog .article_title a {
    font-size: 24px;
    color: #000
}

.blog .article_title a.btn {
    font-size: 10px;
    color: #fff
}

.blog .article_title {
    margin-bottom: 10px
}

.contactForm h1 {
    font-size: 64px
}

.contactForm .contactTitle {
    max-width: 60%;
    margin: 30px auto 100px
}

.moveStackUp.contactForm {
    top: -200px;
    position: relative;
    z-index: 999;
    margin-bottom: -150px
}

.blueAccent {
    color: #0a71f0;
    font-weight: 700
}

.ctaBlog {
    background-image: url(./images/cta-bg.jpg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 50px 0;
    border: 1px solid #c6c6c6;
    padding: 40px
}

.ctaBlog h2 {
    font-size: 24px
}

.ctaBlog p {
    font-size: 14px;
    color: #000
}

.faq div.rolehidden {
    display: none
}

@media only screen and (max-width:700px) {
    .footerSlice .footermenutitle {
        cursor: pointer
    }

    .footerSlice ul {
        display: none
    }

    .footerSlice ul.show {
        display: inherit
    }

    .footermenutitle {
        border-bottom: 1px solid #e2e2e2;
        margin-bottom: 10px
    }
}

#revealFooterToggle {
    line-height: 1.4em
}

.dgray {
    background-color: #333
}

.lgray {
    background-color: #f5f3f5
}

.dgray h1,
.dgray h2,
.dgray h3,
.dgray p {
    color: #fff
}

.blueLine {
    padding-bottom: 20px
}

.blueLine:after {
    border-bottom: 4px solid #c8dae9;
    width: 50px;
    content: '';
    display: block;
    margin: auto;
    top: 16px;
    position: relative
}

.padding175 {
    padding: 175px 0
}

.padding75 {
    padding: 75px 0
}

.padding110 {
    padding: 110px 0
}

.padding200 {
    padding: 200px 0
}

.paddingTop75 {
    padding-top: 75px
}

.paddingBottom75 {
    padding-bottom: 75px
}

.paddingBottom25 {
    padding-bottom: 25px
}

.paddingBottom35 {
    padding-bottom: 35px
}

.paddingBottom50 {
    padding-bottom: 50px
}

.padding50 {
    padding: 50px 0
}

.paddingTop50 {
    padding-top: 50px
}

.padding25 {
    padding: 25px 0
}

@media(min-width:992px) {
    .paddingX50 {
        padding-right: 50px;
        padding-left: 50px
    }
}

.productHeader {
    background-color: #e6e6e6;
    padding: 25px 0 60px;
    min-height: 600px
}

.lovation_he-il .productHeader {
    overflow-x: hidden
}

#advantageColCircles .row.cnt6,
#advantageColCircles.advantegeCirclesInsideTab,
.advantegeCirclesInsideTab .row.cnt6,
.advantage-circles .row.cnt6,
.advantage-circles .advantegeCirclesInsideTab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center
}

.advantegeCirclesInsideTab .col-md-3,
#advantageColCircles .col-md-3,
.advantage-circles .col-md-3 {
    min-height: 410px
}

#advantage-circles.advantegeCirclesInsideTab p,
.advantage-circles.advantegeCirclesInsideTab p,
.advantegeCirclesInsideTab p {
    text-align: center
}

.img-max-width-100 img {
    max-width: 100%;
    height: auto
}

.advantegeCirclesInsideTab a img,
#advantageColCircles a img,
.advantage-circles a img {
    transition: all .2s ease-in-out
}

.advantegeCirclesInsideTab a:hover img,
#advantageColCircles a:hover img,
.advantage-circles a:hover img {
    transform: scale(1.1)
}

.advantegeCirclesInsideTab .row.cnt6 div.col-md-6.col-sm-12:nth-child(2) a:hover img,
#advantageColCircles .row.cnt6 div.col-md-6.col-sm-12:nth-child(2) a:hover img,
.advantage-circles .row.cnt6 div.col-md-6.col-sm-12:nth-child(2) a:hover img {
    transform: scale(1.05)
}

.advantegeCirclesInsideTab .col-md-6.col-sm-12:nth-child(2),
#advantageColCircles .col-md-6.col-sm-12:nth-child(2),
.advantage-circles .col-md-6.col-sm-12:nth-child(2) {
    margin-bottom: 30px
}

.aesthetic {
    background-image: url(./images/doctor.png);
    background-size: cover;
    padding: 150px 0
}

.partnership {
    background-image: url(./images/patnership_bg.jpg);
    background-size: cover;
    padding: 150px 0;
    background-position: left top;
    background-repeat: no-repeat
}

.subscriptionModel {
    background-image: url(./images/subscription_bg.jpg);
    background-size: cover;
    padding: 150px 0;
    background-position: center;
    border-top: 1px solid #dbd7d7
}

.vero-advancedProvenTech {
    background-image: url(./images/vero-proven-tech.jpg);
    background-size: cover;
    padding: 150px 0;
    background-position: center;
    border-top: 1px solid #dbd7d7
}

#vero-preceptprships {
    background-image: url(./images/vero-preceptprships.jpg);
    background-size: cover;
    padding: 100px 0;
    background-position: center
}

.vero-marketingTools {
    background-image: url(./images/vero-marketing-tools.jpg);
    background-size: cover;
    padding: 150px 0;
    background-position: center;
    border-top: 1px solid #dbd7d7
}

#agency-partnership {
    background-image: url(./images/2two5_BG.jpg);
    background-size: 33%;
    background-repeat: no-repeat;
    background-position: left
}

.subscriptionModel li,
.practiceEnhancements li,
.continuousEducation li,
.partnership li,
.businessDev li,
.easyUpgrades li {
    padding: 5px 0
}

.subscriptionModel ul,
.practiceEnhancements ul,
.continuousEducation ul,
.partnership ul,
.businessDev ul,
.easyUpgrades ul {
    padding-top: 10px
}

#enhancementslice {
    background-image: url(./images/practice-ehancement-programs-bg.jpg);
    background-size: cover;
    padding: 100px 0;
    background-position: center
}

#subscriptionPlan,
#practiceEnhancementProgram,
#marketingSupport,
#neograftersProgram,
#revenueShareProgram {
    background-size: cover;
    padding: 150px 0;
    background-position: center
}

#subscriptionPlan {
    background-image: url(./images/subscription_plan_bg.jpg)
}

#practiceEnhancementProgram {
    background-image: url(./images/enhancement_program_bg.jpg)
}

#marketingSupport {
    background-image: url(./images/advertising_support_bg.jpg)
}

#neograftersProgram {
    background-image: url(./images/negrafters_program_bg.jpg)
}

#revenueShareProgram {
    background-image: url(./images/revenue_share_program_bg.jpg)
}

#neograftersslice,
#preceptorshipslice,
#jackfisherslice {
    background-image: url(./images/neografters_bg.jpg);
    background-size: 33%;
    background-repeat: no-repeat;
    background-position: left
}

#neograftersslice {
    background-image: url(./images/neografters_bg.jpg)
}

#preceptorshipslice {
    background-image: url(./images/benefits_bg_rep2.jpg)
}

#jackfisherslice {
    background-image: url(./images/dr_fisher.jpg);
    background-position: top right
}

@media screen and (max-width:991px) {

    #jackfisherslice,
    #preceptorshipslice,
    #neograftersslice,
    #agency-partnership {
        background-position: top center;
        background-size: auto 300px
    }

    #jackfisherslice .col-md-8,
    #preceptorshipslice .col-md-8,
    #neograftersslice .col-md-8,
    #agency-partnership .col-md-8 {
        padding-top: 300px
    }

    #jackfisherslice .padding110,
    #preceptorshipslice .padding110,
    #neograftersslice .padding110 {
        padding: 50px 0
    }
}

@media screen and (max-width:767px) {
    .text-center-767 {
        text-align: center
    }
}

@media screen and (max-width:420px) {

    #jackfisherslice,
    #preceptorshipslice,
    #neograftersslice {
        background-position: center top;
        background-size: 100% auto
    }

    #preceptorshipslice .col-md-8,
    #neograftersslice .col-md-8,
    #jackfisherslice .col-md-8 {
        padding-top: 145%
    }
}

#strategicPlanning,
#ongoingSupport,
#financePlanning,
#clinicalSupport,
#revenueShareForYou {
    background-size: 41%;
    background-repeat: no-repeat
}

#strategicPlanning hr,
#ongoingSupport hr,
#financePlanning hr,
#clinicalSupport hr {
    border-top: 1px solid #cacbca
}

#strategicPlanning {
    background-image: url(./images/planning_effecienty_bg.jpg);
    background-position: right
}

#ongoingSupport {
    background-image: url(./images/marketing_support_bg.jpg);
    background-position: left
}

#financePlanning {
    background-image: url(./images/planning_bg.jpg);
    background-position: right
}

#clinicalSupport {
    background-image: url(./images/clinical_technical_support_bg.jpg);
    background-position: left
}

#revenueShareForYou {
    background-image: url(./images/monthly_revenue_bg.jpg);
    background-position: left
}

@media screen and (max-width:991px) {

    #strategicPlanning,
    #ongoingSupport,
    #financePlanning,
    #clinicalSupport,
    #revenueShareForYou {
        background-position: top center;
        background-size: auto 300px
    }

    #strategicPlanning .col-md-7,
    #ongoingSupport .col-md-7,
    #financePlanning .col-md-7,
    #clinicalSupport .col-md-7,
    #revenueShareForYou .col-md-7 {
        padding-top: 300px
    }

    #revenueShareForYou .padding200 {
        padding: 50px 0
    }
}

@media screen and (max-width:420px) {

    #strategicPlanning,
    #ongoingSupport,
    #financePlanning,
    #clinicalSupport,
    #revenueShareForYou {
        background-size: 100% auto
    }

    #strategicPlanning .col-md-7,
    #ongoingSupport .col-md-7,
    #financePlanning .col-md-7,
    #clinicalSupport .col-md-7,
    #revenueShareForYou .col-md-7 {
        padding-top: 135%
    }
}

.annotation {
    color: #757575;
    font-size: 14px
}

#educationslice {
    background-image: url(./images/clinical-education-bg.jpg);
    background-size: cover;
    padding: 100px 0;
    background-position: center
}

#veroslice {
    background-image: url(./images/vero-verografters.jpg);
    background-size: cover;
    padding: 100px 0;
    background-position: center
}

.easyUpgrades {
    background-image: url(./images/upgrades_bg.jpg);
    background-size: cover;
    background-color: #5a6671;
    padding: 150px 0;
    background-position: center
}

.businessDev {
    background-image: url(./images/vero-Business-dev.jpg);
    background-size: cover;
    background-color: #fff;
    padding: 150px 0;
    background-position: center
}

.easyUpgrades h2,
.easyUpgrades p,
.easyUpgrades li {
    color: #fff
}

.tickUL ul {
    list-style-image: url(./images/checkmark.png)
}

.warrantyBG {
    background-image: url(./images/warrantly_bg.jpg);
    background-size: cover;
    background-position: top right;
    padding-right: 230px
}

.expert {
    background-color: #f5f4f4
}

.darkExpert .expert {
    background-color: #8b8b8b
}

.darkExpert .expert h2,
.darkExpert .expert p {
    color: #fff
}

.absCricle {
    position: absolute;
    left: 100%;
    right: 0;
    top: -170px
}

.wrapProgram {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #9e9fa4
}

.programIcon {
    width: 35px;
    height: 25px;
    display: block;
    float: left
}

.programText {
    padding-left: 35px;
    display: block
}

.productHover {
    width: 100%;
    overflow: hidden;
    padding: 30px;
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -ms-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out;
    position: relative
}

.productHover .btn {
    opacity: 1
}

.productHover:after {
    border: 10px solid transparent;
    position: absolute;
    top: 225px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    content: '';
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -ms-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out
}

.productHover p {
    z-index: 3;
    position: relative
}

.productHover:hover:after {
    border: 10px solid #e5e5e5
}

.productHoverBorder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2
}

.productHover .prodImage,
.productHover .prodImage img {
    z-index: 3
}

.productHover:hover .btn {
    opacity: 1
}

.compareProd {
    position: relative;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3
}

.productPage p,
.productPage a {
    position: relative;
    z-index: 3
}

.toggleText {
    cursor: pointer;
    text-transform: uppercase
}

.toggleText .color {
    color: #fe6a6a
}

.productCaro .slide {
    border: 10px solid #fff
}

.productCaro .slide .btn,
.productCaro .slide .prodLogo,
.productCaro .slide .prodDesc {
    opacity: 0
}

.productCaro .slide:hover {
    border: 10px solid #e5e5e5
}

.productCaro .slide:hover .btn,
.productCaro .slide:hover .prodLogo,
.productCaro .slide:hover .prodDesc {
    opacity: 1
}

.transition {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3)
}

.tickUL li {
    margin-bottom: 20px
}

.tickUL ul {
    padding: 35px 45px
}

.carousel-control {}

.carousel-inner>.item {
    padding: 10px 70px
}

.results .carousel-inner>.item {
    padding: 0 70px
}

.results .carousel-inner .item>div,
.results .carousel-inner .item>div>div {
    border: 1px solid transparent
}

.setTabColor .nav-tabs>li.active>a,
.setTabColor .nav-tabs>li.active>a:focus,
.setTabColor .nav-tabs>li.active>a:hover {
    background-color: unset
}

.setTabColor table {
    margin-bottom: 65px
}

#devices .nav-tabs a {
    text-transform: uppercase;
    color: #333
}

body.legacy #devices .nav-tabs li.active {
    background-color: #7d0041
}

body.legacy #devices .nav-tabs li.active a {
    color: #fff
}

body.legacy .changeColor h3 {
    color: #7d0041
}

body.legacy .nav-tabs li.active a {
    color: #fff
}

body.velocity #devices .nav-tabs li.active {
    background-color: #902333
}

body.velocity #devices .nav-tabs li.active a {
    color: #fff
}

body.velocity .changeColor h3 {
    color: #902333
}

body.velocity .nav-tabs li.active a {
    color: #fff
}

body.versa .nav-tabs li.active {
    background-color: #9ece42
}

body.versa .changeColor h3 {
    color: #9ece42
}

body.versa #devices .nav-tabs li.active a {
    color: #fff
}

body.skin .nav-tabs li.active {
    background-color: #0cabd9
}

body.skin .changeColor h3 {
    color: #0cabd9
}

body.skin #devices .nav-tabs li.active a {
    color: #fff
}

body.viva .nav-tabs li.active {
    background-color: #f04e23
}

body.viva .changeColor h3 {
    color: #f04e23
}

body.viva #devices .nav-tabs li.active a {
    color: #fff
}

body.freeze .nav-tabs li.active {
    background-color: #004c8f
}

body.freeze .changeColor h3 {
    color: #004c8f
}

body.freeze #devices .nav-tabs li.active a {
    color: #fff
}

body.neograft .nav-tabs li.active {
    background-color: #1b2a38
}

body.neograft .changeColor h3 {
    color: #1b2a38
}

body.neograft #devices .nav-tabs li.active a {
    color: #fff
}

body.fiore .nav-tabs li.active {
    background-color: #ac4298
}

body.fiore .changeColor h3 {
    color: #ac4298
}

body.fiore #devices .nav-tabs li.active a {
    color: #fff
}

.setTabColor {
    padding-bottom: 50px
}

.setTabColor .inactive {
    background-color: transparent !important
}

.setTabColor .freeze.active {
    background-color: #004c8f
}

.setTabColor .freeze.active>a {
    color: #fff !important
}

.setTabColor .legacy.active {
    background-color: #7d0041
}

.setTabColor .legacy.active>a {
    color: #fff !important
}

.setTabColor .versa.active {
    background-color: #9ece42
}

.setTabColor .versa.active>a {
    color: #fff !important
}

.setTabColor .viva.active {
    background-color: #f04e23
}

.setTabColor .viva.active>a {
    color: #fff !important
}

.setTabColor .velocity.active {
    background-color: #902333
}

.setTabColor .velocity.active>a {
    color: #fff !important
}

.setTabColor .neograft.active {
    background-color: #1b2a38
}

.setTabColor .neograft.active>a {
    color: #fff !important
}

.setTabColor .fiore.active {
    background-color: #ac4298
}

.setTabColor .fiore.active>a {
    color: #fff !important
}

.setTabColor .epileve.active>a {
    color: #fff !important
}

.setTabColor .heal.active>a {
    color: #fff !important
}

.setTabColor .bliss.active>a {
    color: #fff !important
}

#devices .tab-content {
    padding: 40px 0
}

#devices .tab-content h3 {
    padding-top: 25px
}

#devices .tab-content h4 {
    padding-top: 25px;
    padding-bottom: 10px
}

#devices .nav-tabs {
    background-color: #f3f0f0;
    border-bottom: 0
}

.lang_he #devices .nav-tabs {
    padding-right: 0
}

.lang_he #devices .nav-tabs>li {
    float: right
}

#devices table tr {
    border-bottom: 1px solid #333;
    padding-bottom: 25px;
    margin-bottom: 25px
}

#devices table tr td {
    padding: 15px 0
}

#devices table tr .col1 {
    width: 35%
}

.otherProducts {
    width: 13%;
    display: inline-block;
    text-align: center;
    float: left;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    padding: 10px 0;
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -ms-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out
}

.otherProducts .pImage {
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;
    transform: scale(.8);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: gray
}

.otherProducts:hover .pImage {
    transform: scale(1);
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: gray
}

.otherProducts .pLogo {
    opacity: 0;
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -ms-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out
}

.otherProducts:hover .pLogo {
    opacity: 1
}

.otherProducts.versa:hover {
    border-top: 5px solid #99c93c;
    border-bottom: 5px solid #99c93c
}

.otherProducts.legacy:hover {
    border-top: 5px solid #7d0141;
    border-bottom: 5px solid #7d0141
}

.otherProducts.velocity:hover {
    border-top: 5px solid #a21f35;
    border-bottom: 5px solid #a21f35
}

.otherProducts.viva:hover {
    border-top: 5px solid #f95124;
    border-bottom: 5px solid #f95124
}

.otherProducts.freeze:hover {
    border-top: 5px solid #034e92;
    border-bottom: 5px solid #034e92
}

.otherProducts.skin:hover {
    border-top: 5px solid #9b9997;
    border-bottom: 5px solid #9b9997
}

.otherProducts.neograft:hover {
    border-top: 5px solid #1b2a38;
    border-bottom: 5px solid #1b2a38
}

.otherProducts.fiore:hover {
    border-top: 5px solid #ac4298;
    border-bottom: 5px solid #ac4298
}

.otherProducts span {
    width: 100%;
    padding: 25px 0;
    text-align: center;
    float: left
}

.investmentCols {
    text-align: center;
    padding-top: 55px
}

.investmentCols .col-md-4 {
    min-height: 290px;
    vertical-align: top
}

.ArticleCol1 {
    width: 100%
}

.ArticleCol2 {
    width: 49%;
    display: inline-block;
    vertical-align: top
}

.ArticleCol3 {
    width: 100%;
    display: inline-block;
    padding: 30px 30px 10px
}

.MidArticle {
    border-top: 1.5px solid #6c6c6c;
    border-bottom: 1.5px solid #6c6c6c
}

.careerList {
    border-bottom: 1px solid #fff
}

.careerList article {
    border-top: 1.5px solid #6c6c6c
}

#upgrade {
    background-color: #ffc;
    padding: 8px;
    text-align: center;
    color: #000;
    font-size: 12px
}

#innovasium {
    display: block;
    margin: 0;
    padding: 0
}

#innovasium a {
    display: inline-block;
    width: auto;
    height: 20px;
    background-image: url(./images/logo_innovasium_16x16.png);
    background-position: left top;
    background-repeat: no-repeat;
    padding-left: 24px;
    font-size: 11px;
    color: #fff
}

.loading {
    background-image: url(./images/mozilla_blu.gif);
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 16px;
    min-width: 16px
}

form.loading .form-group {
    opacity: .2;
    -moz-opacity: .2;
    -webkit-opacity: .2
}

.newsLanding article {
    background-color: #fff;
    border: 1px solid #cfcdcd;
    margin: 20px 0;
    padding: 25px 0
}

.newsLanding article .article_image img {
    width: 100%;
    height: 300px;
    object-fit: cover
}

.newsLanding article .article_location {
    font-size: 18px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 24px
}

.location-icon {
    margin-right: 15px;
    margin-bottom: 15px;
    float: left
}

.newsLanding article .article_date_created,
.newsLanding article .article_title,
.newsLanding article .article_excerpt,
.newsLanding article .article_categorys,
.newsLanding article .article_location,
.newsLanding article .article_date_start {
    padding: 5px 25px
}

.newsLanding article .article_date_start {
    padding-right: 2px
}

.newsLanding article .article_title a {
    font-size: 24px;
    font-weight: 700;
    color: #333
}

.newsLanding article .article_title {
    display: block;
    overflow: hidden;
    height: auto
}

.newsLanding .article_image {
    margin: 20px 0;
    display: block;
    overflow: hidden;
    height: auto
}

.newsLandingSide article .article_image,
.eventsLandingSide article .article_image {
    display: inline-block;
    width: 120px
}

.newsLandingSide article .article_image img,
.eventsLandingSide article .article_image img {
    max-width: 110px;
    height: auto
}

.whiteBG {
    background-color: #fff
}

.contactForm {
    background-color: #f5f5f5;
    padding: 40px
}

.contactForm.padding100 {
    padding-top: 50px !important
}

#SpeakToExpert .content {
    margin: 40px auto;
    width: 90%;
    max-width: 875px
}

.expertForm div.field {
    width: 49%;
    display: inline-block
}

#ExpertModal {
    background-color: #f3f3f3;
    padding: 70px;
    position: relative
}

#ExpertModal .col-sm-4,
#ExpertModal .col-sm-8 {
    padding-left: 0
}

#ExpertModal .close1.newclose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer
}

#TeamDetailsReveal .content {
    margin: 40px auto;
    width: 90%;
    max-width: 875px
}

#TeamModal {
    background-color: #f3f3f3;
    padding: 40px 70px;
    position: relative
}

#TeamModal .close1.newclose {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer
}

#TeamModal h2 {
    font-weight: 600;
    padding-bottom: 0
}

.videoTreatment {
    background-color: #000
}

.videoTreatment .container {
    width: 100%;
    overflow: hidden
}

.videoTreatment .container iframe {
    width: 100%;
    height: 750px
}

.videoTreatment p {
    margin-bottom: -10px !important
}

.productCaro .prodLogo {
    text-align: center
}

.productCaro .prodLogo img,
.productCaro .prodImage img {
    display: inline-block
}

.productCaro .compareProd {
    position: relative;
    top: auto;
    margin-bottom: 15px
}

.productPage .bx-pager {
    display: none
}

.customCaro .widget_bootstrapcarousel {
    padding: 50px 0
}

.customCaro .widget_bootstrapcarousel .carousel-inner .col-md-8 {
    padding: 40px;
    background-color: #fff;
    height: 100%;
    min-height: 494px
}

.customCaro .carousel-control.left,
.customCaro .carousel-control.right {
    background-color: #c6c6c6;
    padding: 0 20px
}

.customCaro .carousel-control.left,
.customCaro .carousel-control.right {
    background-image: none !important;
    filter:none !important;
    background-color: #0faefb !important
}

.tab-ProductWrapper {
    display: block;
    overflow: hidden
}

.tab-ProductImage {
    display: inline-block;
    width: 170px;
    float: left
}

.lang_he .tab-ProductImage {
    float: right
}

.tab-ProductContent {
    padding-left: 190px
}

.lang_he .tab-ProductContent {
    padding-right: 190px;
    padding-left: 0
}

body.secondary #contents {
    padding-top: 180px
}

body.graybg {
    background-color: #f3f3f3
}

.productGrid {
    padding: 40px 0
}

.emailForm .col-sm-8 {
    padding-left: 0
}

.emailForm {
    padding-top: 10px
}

.emailForm .form-control {
    background-color: transparent;
    border: 0 solid #ccc;
    border-radius: 0;
    border-bottom: 2px solid #838383;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, .075);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, .075);
    padding-left: 0
}

.emailForm ::-webkit-input-placeholder {
    font-size: 18px;
    color: #000
}

.emailForm ::-moz-placeholder {
    font-size: 18px;
    color: #000
}

.emailForm :-ms-input-placeholder {
    font-size: 18px;
    color: #000
}

.emailForm input:-moz-placeholder {
    font-size: 18px;
    color: #000
}

.executiveBG,
.articleTeam {
    background-color: #f7f6f6;
    padding: 40px 0
}

.articleTeam article {
    width: 33%;
    display: inline-block;
    padding: 20px 10px;
    vertical-align: top
}

.articleTeam .article_date_created {
    display: none
}

.articleTeam .article_image {
    padding: 35px 0
}

.articleTeam .article_titlenolink {
    color: #43484d;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px
}

.articleTeam .article_titlenolink {
    padding-bottom: 5px
}

.articleTeam .article_excerpt {
    padding-top: 25px
}

.bluePlus {
    color: #70b0fc;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase
}

.ImageRightStick img {
    position: absolute;
    left: 0;
    top: 0
}

.ImageRightStick {
    display: block;
    overflow: hidden
}

.darkGray {
    background-color: #706f6f;
    padding: 45px 0
}

.darkerGray {
    background-color: #4a4a4a
}

.darkGray h2,
.darkGray p,
.darkerGray h2,
.darkerGray p {
    color: #fff
}

.ceoslice {
    background-color: #ecebeb
}

.ceoslice .article_date_created,
.ceoslice .article_tags {
    display: none
}

.ceoslice .article_image {
    width: 30%;
    float: left;
    display: inline-block;
    margin-right: 25px
}

.ceoslice .article_titlenolink {
    padding-top: 70px;
    display: inline-block;
    color: #43484d;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding-bottom: 10px
}

.lang_bg .ceoslice .article_titlenolink {
    padding-top: 40px
}

.ceoslice .article_html {
    padding-top: 25px
}

.ceoslice .article_titlenolink {
    color: #43484d;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px
}

.coreGraphic {
    padding-bottom: 30px
}

.coreGraphic img {
    width: 100%;
    height: auto
}

.customCaro .col-md-8,
.customCaro .col-md-4 {
    width: 50%
}

.customCaro .col-md-4 {
    padding-right: 6px
}

.regions h3,
.regions .title-h3 {
    border-bottom: 2px solid #d8d8d8
}

.regions table {
    margin-top: 35px;
    margin-bottom: 80px;
    width: 100%
}

.regions table td {
    padding: 20px 0;
    width: 25%
}

.regions table td img {
    margin-right: 15px
}

.careerList .article_date_created {
    display: none
}

.careerList .article_excerpt {
    color: #fff;
    min-height: 55px;
    overflow: hidden;
    position: relative
}

#clinicalresults .col-md-8,
#clinicalresults .col-md-4 {
    width: 50%
}

#clinicalresults .results .col-md-4 {
    padding-right: 6px
}

#clinicalresults .widget_bootstrapcarousel .carousel-inner .col-md-8,
#clinicalresults .widget_bootstrapcarousel_before_after .carousel-inner .col-md-8 {
    padding: 40px;
    background-color: #fff;
    height: 100%;
    min-height: 494px
}

.article-shim {
    background-image: url(./images/careerGradient.png);
    background-position: 0 100%;
    background-repeat: repeat-x;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 8px;
    height: 20px;
    background-color: transparent
}

.careerList .article_title a {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
    display: block
}

#businessAdvantage .type_htmltext {
    position: relative
}

.careerList .article_detail_partial {
    vertical-align: top;
    display: flex;
    flex-direction: column
}

.careerList .article_detail_full .article_title {
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 20px;
    border-bottom: 1px solid #979797;
    margin-bottom: 35px
}

#ExpertModal label {
    display: block;
    text-transform: uppercase
}

#ExpertModal .col-sm-4,
#ExpertModal .col-sm-8 {
    width: 100%
}

#ExpertModal .type_checkbox>label {
    display: none
}

#ExpertModal input {
    border: 1px solid #c8c5c5;
    border-radius: 0;
    padding: 5px;
    max-width: 100% !important;
    -webkit-box-shadow: inset 2px 2px 2px 0 #ddd;
    -moz-box-shadow: inset 2px 2px 2px 0 #ddd;
    box-shadow: inset 2px 2px 2px 0 #ddd
}

.submitResume>.container {
    border-top: 1px solid #979797;
    padding-top: 50px
}

.submitResume form {
    padding: 40px 0
}

.submitResume form div.field {
    width: 49%;
    display: inline-block;
    vertical-align: top
}

.submitResume .col-sm-4,
.submitResume .col-sm-8 {
    width: 100%
}

.submitResume label {
    display: block;
    text-transform: uppercase;
    font-size: 12px
}

.submitResume .btn {
    background-color: #0a71f0;
    border: 1px solid #9b9b9b;
    color: #fff
}

.submitResume input,
.submitResume textarea {
    border: 1px solid #c8c5c5;
    border-radius: 0;
    padding: 5px;
    max-width: 100% !important;
    -webkit-box-shadow: inset 2px 2px 2px 0 #ddd;
    -moz-box-shadow: inset 2px 2px 2px 0 #ddd;
    box-shadow: inset 2px 2px 2px 0 #ddd
}

.submitResume input.file {
    padding: 0 25px 0 0;
    background-image: url(./images/dir.png);
    background-position: 98% center;
    background-repeat: no-repeat
}

.mainArticleImage {
    margin-bottom: 30px;
    overflow: hidden
}

.newsStackLeft iframe {
    width: 100%
}

.blueColor {
    color: #0a71f0
}

.expertForm {
    padding: 20px 0
}

.expertForm .req {
    display: none
}

.expertForm .btn {
    background-color: #0a71f0
}

.type_checkbox>label {
    display: none
}

.absCircle {
    position: absolute;
    top: 125px;
    left: 0;
    right: 0;
    text-align: center;
    display: none
}

.article_detail_search .btn:hover {
    opacity: 1
}

#businessAdvantage .id_html {
    position: relative
}

.results .carousel-indicators {
    bottom: -80px
}

.results .carousel-indicators li {
    height: 17px;
    width: 17px;
    border: 1px solid #979797;
    background-color: #fff;
    border-radius: 17px
}

.results .carousel-indicators .active {
    width: 17px;
    height: 17px;
    border: 1px solid #979797;
    background-color: #c6c6c6
}

.results .carousel-control {
    width: 77px;
    opacity: 1
}

.results .carousel-control.left {
    border-right: 12px solid #f5f3f5
}

.results .carousel-control.right {
    border-left: 12px solid #f5f3f5
}

.results .glyphicon-chevron-left:before {
    background-image: url(./images/left_arrow.png) !important;
    content: '';
    width: 16px;
    height: 30px;
    display: block
}

.results .glyphicon-chevron-right:before {
    background-image: url(./images/right_arrow.png) !important;
    content: '';
    width: 16px;
    height: 30px;
    display: block
}

.results .carousel-control .glyphicon-chevron-right,
.results .carousel-control .icon-next {
    right: 30% !important;
    margin-right: -10px
}

#clinicalresults .results .widget_bootstrapcarousel .carousel-inner .col-md-8,
#clinicalresults .results .widget_bootstrapcarousel_before_after .carousel-inner .col-md-8,
.results #clinicalresults .widget_bootstrapcarousel .carousel-inner .col-md-8,
.results #clinicalresults .widget_bootstrapcarousel_before_after .carousel-inner .col-md-8 {
    padding: 40px;
    background-color: #fff !important
}

#clinicalresults .results .widget_bootstrapcarousel,
#clinicalresults .results .widget_bootstrapcarousel_before_after,
.results #clinicalresults .widget_bootstrapcarousel,
.results #clinicalresults .widget_bootstrapcarousel_before_after {
    padding: 50px 0 !important
}

#clinicalresults .results .carousel-control.left,
#clinicalresults .results .carousel-control.right,
.results #clinicalresults .carousel-control.left,
.results #clinicalresults .carousel-control.right {
    background-color: #c6c6c6 !important;
    padding: 0 20px
}

.footerAccordion {
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: .4s
}

.footerAccordion.active,
.footerAccordion:hover {
    background-color: transparent
}

.footerPanel {
    padding: 0 18px;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-out
}

h1,
h2,
h3,
h4,
h5 {
    margin-top: 0;
    padding-top: 0
}

.teamDesignation {
    color: #43484d;
    font-size: 38px;
    font-weight: 300;
    line-height: 48px
}

.testimonialCaro .carousel-inner {
    height: auto;
    min-height: 310px
}

#teamstackDetailsReveal,
#leadershiptackDetailsReveal {
    padding: 55px 0
}

.regions table td a {
    color: #4a4a4a;
    font-size: 16px;
    line-height: 21px
}

.btn.articlesmorebutton {
    margin-bottom: 40px;
    margin-top: 0;
    width: 100%;
    border-radius: 0;
    padding: 30px;
    font-size: 15px
}

.customImgTreatment img {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 500%
}

.contactIcon {
    width: 40px;
    display: inline-block;
    float: left
}

.contactText {
    padding-left: 50px
}

.contactWrap {
    padding-bottom: 30px
}

.sectionHeader {
    padding: 25px 0
}

.sectionHeader h3,
.sectionHeader h4 {
    border-bottom: 1px solid #d8d8d8
}

.addressTable table {
    width: 100%
}

.addressTable table td {
    width: 33%;
    padding-left: 70px;
    position: relative;
    padding-bottom: 50px;
    vertical-align: top
}

.lang_he .addressTable table td {
    padding-right: 60px
}

.addressTable table td img {
    position: absolute;
    top: 5px;
    left: 0
}

.adjustcol4 .col-md-4 {
    height: auto;
    min-height: 270px
}

.adjustcol4 .col-md-4.id4,
.adjustcol4 .col-md-4.id5,
.adjustcol4 .col-md-4.id6 {
    min-height: 220px
}

.submitResume .blueColor {
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    padding-top: 15px
}

.devicesCols .col-md-4 {
    min-height: 695px;
    height: auto;
    width: 32.33%;
    float: none;
    display: inline-block;
    vertical-align: top
}

.article_detail_full img {
    max-width: 100%;
    height: auto
}

.article_detail_full .article_location {
    padding-bottom: 25px
}

.article_location {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 25px;
    padding-bottom: 25px !important
}

.article_html form {
    padding: 75px 0
}

.careerList .btn {
    background-color: transparent;
    border: 0;
    padding: 15px 0
}

.career .picTable td {
    text-align: Center;
    width: 33%
}

.picTable {
    padding: 50px 0;
    width: 100%;
    border: 0;
    border-collapse: separate;
    margin-bottom: 0 !important
}

body.legacy .picTable td {
    color: #7d0041
}

body.freeze .picTable td {
    color: #004c8f
}

.picTable img {
    display: block;
    margin: 20px auto
}

.careerDetail .article_image {
    display: none
}

.article_detail_full .article_date_start,
.article_detail_full .article_date_end {
    display: inline-block
}

.mobileImage {
    display: none
}

.topmenu {
    display: none;
    cursor: pointer
}

.topmenu {


    color: #fff;
    top: 27px;
    position: relative;
    font-size: 10px;
    font-weight: 700
}

.modal-body {
    position: relative;
    padding: 50px 50px 30px;
    background-color: #f3f3f3
}

.modal-content {
    border-radius: 0
}

.modal-body .pull-right {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: #fff;
    text-decoration: none
}

.blog {
    background-image: url(./images/blog_bgg.jpg);
    background-size: cover;
    background-position: top left;
    min-height: 600px;
    height: auto
}

.blog .article_image {
    margin-bottom: 25px
}

.feelgreat {
    background-image: url(./images/first_step_bg.jpg);
    background-size: cover;
    background-position: top right;
    min-height: 500px;
    height: auto;
    padding-top: 122px
}

.certified {
    background-image: url(./images/provider_bg.jpg);
    background-size: cover;
    background-position: center;
    min-height: 400px;
    height: auto;
    color: #fff;
    padding-top: 100px
}

.certified h2 {
    color: #fff
}

.sm {
    font-size: 12px;
    line-height: 14px;
    padding-top: 20px !important
}

.findform form {
    width: auto;
    display: inline-block;
    border: 1px solid #333;
    height: 48px;
    overflow: hidden;
    position: relative
}

.findform input {
    color: #333;
    width: 300px;
    border: 0;
    background-color: #fff;
    padding: 10px 15px;
    height: 46px;
    margin: 0 46px 0 0;
    display: inline-block
}

.findform .btn {
    margin: 0;
    background-image: url(./images/search-button.jpg);
    background-repeat: no-repeat;
    border-radius: 0;
    width: 46px;
    text-indent: 25px;
    overflow: hidden;
    background-size: cover;
    height: 46px;
    background-position: center;
    right: 0;
    position: absolute;
    top: 0;
    padding: 0;
    text-indent: 80px
}

.blogLine {
    border-top: 1px solid #979797;
    padding-top: 20px;
    margin-top: 20px
}

.technologyBG {
    background-image: url(./images/technology_detail.jpg);
    background-repeat: no-repeat;
    padding: 50px 0;
    min-height: 650px;
    height: auto;
    border-bottom: 1px solid #666
}

.textWhite h1,
.textWhite p {
    color: #fff
}

.versaCaro {
    min-height: 270px
}

.versaCaro .glyphicon-chevron-left::before {
    content: url(./images/grey-arrow-versa-left.png)
}

.versaCaro .glyphicon-chevron-right::before {
    content: url(./images/grey-arrow-versa-right.png)
}

.versaTabs {
    padding: 50px 0 0
}

.widget_bootstrapcollapse {
    margin-bottom: 50px
}

.versaTabs .nav-tabs>li.active>a,
.versaTabs .nav-tabs>li.active>a:focus,
.versaTabs .nav-tabs>li.active>a:hover {
    background-color: #9ece42;
    color: #fff
}

.versaTabs .tab-content,
.skinTabs .tab-content {
    padding: 50px 0
}

.versaTabs .nav-tabs a,
.skinTabs .nav-tabs a {
    color: #3a3a3a;
    font-family: Raleway;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 20px 15px
}

.versaTabs .nav-tabs,
.skinTabs .nav-tabs {
    background-color: #f3f0f0;
    box-shadow: 0 2px 4px 0 rgba(226, 223, 227, .5)
}

.skinTabs .nav-tabs>li.active>a,
.skinTabs .nav-tabs>li.active>a:focus,
.skinTabs .nav-tabs>li.active>a:hover {
    background-color: #0cabd9;
    color: #fff
}

.skinTabs .tab-content table {
    margin: 25px 0 75px;
    width: 100%
}

.skinTabs .tab-content table td {
    vertical-align: top
}

.skinTabs .tab-content .leftPanel {
    width: 40%
}

.skinTabs .tab-content .rightPanel {
    width: 59%
}

.searchControl .find {
    position: absolute;
    top: 0;
    right: 0
}

.searchControl .find .btn {
    margin-top: 0
}

.searchControl .form-control {
    height: 43px
}

.searchControl .col-md-offset-3 {
    margin-left: 0
}

.comfort {
    background-color: #f1f1f1
}

.comfort .tab-content {
    padding: 75px 0
}

.comfort .nav-tabs {
    background-color: #e7e5e5;
    border-bottom: 0;
    text-align: center
}

.fullWidthTabs .nav-tabs>li {
    float: none;
    display: inline-block
}

.fullWidthTabs .tab-content {
    max-width: 1150px;
    margin: 0 auto
}

.comfort.setTabColor .nav-tabs>li.active>a,
.comfort.setTabColor .nav-tabs>li.active>a:focus,
.comfort.setTabColor .nav-tabs>li.active>a:hover {
    background-color: #0faefb;
    color: #fff
}

.comfort.setTabColor a {
    color: #666
}

.comfort.setTabColor a.btn {
    color: #fff
}

.textBlue {
    color: #3671a5
}

.searchTopIcon {
    padding-left: 20px
}

.addHover p {
    border: 6px solid #f5f3f5;
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -ms-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out;
    padding: 20px;
    display: block;
    text-align: center
}

.addHover p:hover {
    border: 6px solid #e5e5e5
}

.logoLine .id2 {
    border-left: 1px solid #979797
}

.padding150 {
    padding-top: 150px
}

.paddingFAQ {
    padding: 75px 0 10px
}

.paddingFAQ h4 {
    color: #0faefb;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 20px 0
}

body.viva .paddingFAQ h4 {
    color: #eb3300
}

body.versa .paddingFAQ h4 {
    color: #97d700
}

body.freezeplus .paddingFAQ h4 {
    color: #002d72
}

body.freeze .paddingFAQ h4 {
    color: #002d72
}

body.legacy .paddingFAQ h4 {
    color: #6c1d45
}

body.velocity .paddingFAQ h4 {
    color: #9d2235
}

.paddingFAQ h4.panel-title,
body.viva .paddingFAQ h4.panel-title,
body.versa .paddingFAQ h4.panel-title,
body.freezeplus .paddingFAQ h4.panel-title,
body.freeze .paddingFAQ h4.panel-title,
body.legacy .paddingFAQ h4.panel-title,
body.velocity .paddingFAQ h4.panel-title {
    color: #333;
    padding: 0;
    background-image: url(./images/plus-faq.jpg);
    background-position: top right;
    background-repeat: no-repeat;
    position: relative;
    display: block;
    padding: 20px 0
}

.paddingFAQ h4.panel-title.active {
    background-image: url(./images/minus-faq.jpg)
}

.faq .panel-group .panel-heading+.panel-collapse>.list-group,
.faq .panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top: 0 solid #ddd
}

.faq .panel {
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 0 rgba(0, 0, 0, .05)
}

.faq .panel-heading a {
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -ms-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out;
    font-weight: 400;
    color: #000
}

.faq .panel-heading a:hover,
.faq .panel-heading a:focus {
    color: #096be1;
    text-decoration: none
}

.paddingFAQ h4.panel-title a {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0
}

.howitworks {
    background-image: url(./images/how_it_works_bg.jpg);
    background-size: cover;
    background-position: center;
    padding: 0
}

.howitworks.artas {
    background-image: url(./images/artas-ix-how-it-works.jpg)
}

.howitworks .col-md-6 {
    background-image: url(./images/white-bg-new.png);
    padding: 75px 25px
}

.howitworks.legacy {
    background-image: url(./images/how_it_works_bg_1.jpg)
}

.howitworks.velocity {
    background-image: url(./images/how_it_works_bg_3.jpg)
}

.howitworks.viva {
    background-image: url(./images/how_it_works_bg_2.jpg)
}

.howitworks.freeze {
    background-image: url(./images/how_it_works_bg_4.jpg)
}

.howitworks.freezeplus {
    background-image: url(./images/how_it_works_bg_5.jpg)
}

.carousel-control.left,
.carousel-control.right {
    background: unset !important;
    filter: none !important
}

.colorBand {
    background-image: url(./images/colour_bar.jpg);
    background-size: 100%;
    background-position: center;
    min-height: 5px;
    height: auto
}

.whiteBGTrans {
    background-image: url(./images/white-bg-new.png);
    padding-left: 25px;
    padding-right: 25px
}

.fadeed {
    background-color: #000;
    opacity: .9;
    color: white;
}
.smallmenu.fade.in {
    background-color: #000;
    opacity: .9
}

.renderSmallMenu {
    padding-top: 70px
}

.renderSmallMenu .dropdown-menu {
    display: block;
    padding: 10px 0 50px
}

.renderSmallMenu a {
    font-size: 17px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    display: block;
    float: none
}

.renderSmallMenu ul {
    text-align: center;
    list-style-type: none;
    padding-left: 0
}

.lang_he .text-left-he {
    text-align: left !important
}

.renderSmallMenu>ul>li {
    padding: 20px 0
}

#header .renderSmallMenu .dropdown-menu {
    background-image: none;
    display: none;
}

#header .renderSmallMenu .dropdown-menu>li>a,
.fix .renderSmallMenu .dropdown-menu>li>a {
    font-size: 12px
}

#header .nav li:hover,
#header .nav li:focus,
.renderSmallMenu li:hover,
.renderSmallMenu li:hover a,
.renderSmallMenu li:focus,
#header .nav li:hover,
.footerLinks li:hover,
.footerLinks li:focus {
    background-color: transparent !important
}

.modal {
    z-index: 2000;
    display: block !important;
}

ul.nav li.dropdown:hover>ul.dropdown-menu {
    display: block
}

.id_contents>.ffedit {
    top: 300px
}

.footerSlice {
    padding-top: 40px
}

.footerSlice .navbar-nav {
    text-align: left;
    float: left;
    margin-right: 0;
    margin-left: 0
}

.lang_he .footerSlice .navbar-nav {
    text-align: right;
    float: right;
    padding-right: 0
}

.custsomprevNext .carousel-control {
    position: relative;
    color: #000;
    text-align: center;
    text-shadow: 0 0 0 rgba(0, 0, 0, .6);
    filter: alpha(opacity=100);
    opacity: 1;
    font-size: 16px
}

.custsomprevNext {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #999
}

.fullWidthArticleImage .container {
    width: 100%
}

.id_cliniclist .btn {
    background-color: #0a71f0;
    font-size: 15px;
    width: 100%;
    padding: 30px;
    border-radius: 0
}

.jw-icon-display:before[style] {
    content: 'aaa' !important;
    opacity: 0;
    display: none !important
}

.otherProducts {
    float: none
}

#browseProducts p {
    display: inline-block
}

.center-width-sm-70 {
    width: 70%;
    margin-left: auto;
    margin-right: auto
}

.tribella-images-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap
}

.tribella-image-container {
    padding: 15px;
    width: 33%
}

.tribella-image-container p {
    margin-top: 10px
}

.above-header-bar {
    min-height: 70px;
    padding-top: 20px;
    padding-bottom: 11px;
    background-color: #0a71f0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer
}

.above-header-bar .col-md-8 {
    padding-top: 10px
}

.above-header-bar .btn {
    margin-top: 0;
    padding: 9px 25px;
    width: 170px;
    background-color: #fff;
    color: #0a71f0
}

.above-footer-cookie-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    font-weight: 100;
    z-index: 1000;
    padding-top: 11px;
    padding-bottom: 11px;
    background-color: #0a71f0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 22px;
    text-transform: uppercase
}

.above-footer-cookie-bar>.container {
    display: flex;
    align-items: center
}

.above-footer-cookie-bar .btn {
    margin-top: 0;
    padding: 9px 25px;
    min-width: 170px;
    background-color: #fff;
    color: #0a71f0
}

.above-footer-cookie-bar #closeCookieBar {
    cursor: pointer;
    float: right;
    background-image: url(./images/closewhite.png);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: auto;
    color: #000;
    position: absolute;
    padding: 15px;
    top: 15px;
    right: 15px;
    z-index: 2000
}

#footer {
    position: relative
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important
}

.lang_he .pagination>li {
    float: right
}

.lgrey-bg {
    background-color: #fafafa
}

.black-bg {
    background-color: #000
}

.text-white {
    color: #fff
}

.neograft-new-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap
}

.hairRestoration-new-container {
    display: flex;
    flex-wrap: wrap
}

.hairRestoration-new-container .col-md-5 {
    background-repeat: no-repeat;
    background-size: cover
}

.hairRestoration-new-container img {
    display: none
}

.browse-products-caro.slider-container {
    height: 500px
}

.browse-products-caro.slider-container .slider-content {
    height: 100%
}

.browse-products-caro.slider-container .slider-content .slider-single .slider-single-title {
    float: none
}

.browse-products-caro.slider-container .slider-content {
    top: 50%
}

.browse-products-caro.slider-container .slider-right,
.browse-products-caro.slider-container .slider-left {
    top: 50%
}

#redirectModal .modal-dialog {
    margin: 10% auto
}

#redirectModal h2 {
    font-size: 38px;
    margin-bottom: 25px
}

#redirectModal .btn-blue {
    padding-left: 70px;
    padding-right: 70px;
    margin-bottom: 30px;
    margin-top: 30px
}

#redirectModal .col-md-6:first-child {
    border-right: 2px solid #c5c5c5
}

#header .mini-grey-slice {
    background-color: #616161;
    padding: 15px 50px;
    color: #fff;
    font-weight: 600;
    font-size: 13px
}

#header .mini-grey-slice p {
    margin: 0
}

#header .mini-grey-slice a {
    color: #fff
}

#header .mini-grey-slice .top-utility-nav {
    text-align: right
}

#header .mini-grey-slice .btn-group {
    text-transform: uppercase
}

#header .mini-grey-slice .btn-group:not(:first-child) {
    padding-right: 10px
}

#header .mini-grey-slice .btn-group:not(:first-child):before {
    content: '';
    margin-right: 10px;
    border-right: 2px solid #fff
}

#header .mini-grey-slice .btn-group a.selectLang.dropdown-toggle {
    padding-right: 27px;
    padding-bottom: 20px;
    margin-right: 6px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right 3px;
    background-repeat: no-repeat;
    background-size: 18px 11px;
    display: inline-block;
    box-shadow: none;
    display: none
}

#header .mini-grey-slice .btn-group a.selectLang.dropdown-toggle .dropdown {
    transform: rotate(46deg);
    border: solid #fff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 3px
}

#header .mini-grey-slice #regionlist.dropdown-menu {
    width: 280px;
    left: auto;
    right: 0;
    background-color: rgba(76, 76, 76, .96);
    background-image: none;
    padding: 20px 15px;
    top: 148%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px
}

#header .mini-grey-slice #regionlist.dropdown-menu .region-select-description {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-left: 30px;
    text-transform: none
}

#header .mini-grey-slice #regionlist.dropdown-menu .all-regions a {
    padding-left: 34px;
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px
}
.hongkongfl{
    background-image: url(./images//hongkong.png);
    padding-left: 34px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-size: 18px 11px;
    background-position: 4px;
    font-size: 12px;
    background-repeat: no-repeat;
}
.usalfl{
    background-image: url(./images/usalogowidth.png);
    padding-left: 34px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-size: 18px 11px;
    background-position: 4px;
    font-size: 12px;
    background-repeat: no-repeat;
}
#header .mini-grey-slice #regionlist.dropdown-menu .nearest li {
    border-bottom: 1px solid #fff
}

#header .mini-grey-slice #regionlist.dropdown-menu li.active>a {
    background-color: inherit
}

#header .mini-grey-slice #regionlist.dropdown-menu li.active.global>a {
    background-color: transparent
}

#header .socialIconsTop #regionlist.dropdown-menu .nearest li a {
    background: 0 0 !important
}

#header .mini-grey-slice #regionlist.dropdown-menu .nearest li a {
    background-repeat: no-repeat;
    padding-left: 34px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-size: 18px 11px;
    background-position: 4px;
    font-size: 12px
}

#header .mini-grey-slice #regionlist.dropdown-menu .global a {
    background-image: url(./images/gl.png);
    background-repeat: no-repeat;
    padding-left: 34px;
    background-size: 18px 11px;
    background-position: 4px;
    font-size: 12px
}

#header .mini-grey-slice #regionlist.dropdown-menu .current a {
    background-repeat: no-repeat;
    padding-left: 34px;
    background-size: 18px 11px;
    background-position: 4px;
    font-size: 12px
}

.lang_ko .socialIconsTop,
.lang_en .socialIconsTop,
.location_fr-ca .socialIconsTop {
    padding-top: 0
}

@media only screen and (max-width:1023px) {
    #header .mini-grey-slice .col-sm-4 {
        width: 100%;
        text-align: center
    }

    .logoSlice>.container>.row {
        display: flex
    }
}

@media only screen and (max-width:991px) {
    .hairRestoration-new-container img {
        display: inline-block
    }

    .hairRestoration-new-container .col-md-5 {
        background-image: none !important
    }

    .above-footer-cookie-bar>.container {
        display: block
    }

    .above-footer-cookie-bar .col-md-3 {
        text-align: center
    }

    .above-footer-cookie-bar .col-md-3 .btn {
        margin-top: 10px
    }

    .above-header-bar {
        text-align: center;
        font-size: 12px
    }

    .above-header-bar .btn {
        margin-top: 20px
    }

    #redirectModal .btn-blue {
        margin-bottom: 0;
        margin-top: 20px
    }

    #redirectModal .col-md-6:first-child {
        border-right: none
    }
}

@media only screen and (max-width:767px) {
    .above-footer-cookie-bar {
        font-size: 12px;
        text-align: center
    }
}

@media only screen and (max-width:500px) {
    .above-footer-cookie-bar {
        font-size: 10px;
        line-height: 19px
    }
}

a {
    color: #0961ce
}

a:focus,
button:focus,
input:focus {
    color: #0961ce
}

.socialIconsTop a:focus {
    color: #fff
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: #fff
}

.article_category_more .dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: inherit
}

.logoSlice {
    background: rgba(0, 0, 0, .7)
}

.toggleText .color {
    color: #fea4a4
}

.topmenu {
    font-size: .72em
}

.dropdown-menu {
    font-size: .94em
}

.socialIconsTop a {
    font-size: .75em
}

.lang_he .socialIconsTop a {
    font-size: .9em
}

#header .dropdown-menu li>a,
.fix .dropdown-menu li>a {
    font-size: .86em
}

.lang_he #header .dropdown-menu li>a,
.lang_he .fix .dropdown-menu li>a {
    font-size: 1em;
    text-align: right
}

.lang_he #header .renderSmallMenu .dropdown-menu li>a {
    text-align: center
}

.sr-only {
    font-size: 1em
}
.linkkkk{
    color: white;
    text-decoration: none;
    
}
.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-nav>li>a:focus {
    font-size:16px;
    text-decoration: none;
}

.lang_he .navbar-default .navbar-nav>li>a,
.lang_he .navbar-default .navbar-nav>li>a:focus {
    font-size: .9em
}

.lang_he #header .dropdown-menu,
.lang_he .fix .dropdown-menu {
    right: 0
}

.lang_he .headerStack #regionlist ul {
    padding-right: 0;
    -webkit-padding-start: 0
}

.lang_he .renderSmallMenu ul {
    padding-right: 0
}

.renderSmallMenu a {
    font-size: 1.07em
}

.renderSmallMenu a:focus {
    color: #fff
}

#header .renderSmallMenu .dropdown-menu>li>a,
.fix .renderSmallMenu .dropdown-menu>li>a {
    font-size: .86em
}

.cd-image-label {
    background-color: #757575 !important
}

.topImage {
    background-color: #84717c
}

.topImage.textBlack {
    background-color: #ebebeb
}

.hoverWrapper .widgetInfo,
.hoverWrapper .widgetTitle {
    color: #fff
}

.darkExpert .expert {
    background-color: #757575
}

body.skin .changeColor h3 {
    color: #087b9b
}

body.skin .nav-tabs li.active {
    background-color: #087b9b
}

.newsCategoriesTop .badge {
    background-color: #737373
}

.footerStack a.btn-blue {
    color: #fff
}

.articlereadmore {
    color: #737373 !important
}

.newsLandingSide .article_date_created,
.newsLandingSide .article_date_start,
.newsLandingSide .article_date_end,
.eventsLandingSide .article_date_created,
.eventsLandingSide .article_date_start,
.eventsLandingSide .article_date_end {
    color: #6b6b6b !important
}

.topImage .btn {
    opacity: 1
}

.bluePlus {
    color: #0368e2
}

body.legacy #devices .nav-tabs li.active {
    background-color: #0a71f0
}

body.legacy .changeColor h3 {
    color: #0a71f0
}

body.velocity #devices .nav-tabs li.active {
    background-color: #0a71f0
}

body.velocity .changeColor h3 {
    color: #0a71f0
}

body.versa .nav-tabs li.active {
    background-color: #0a71f0
}

body.versa .changeColor h3 {
    color: #0a71f0
}

body.skin .nav-tabs li.active {
    background-color: #0a71f0
}

body.skin .changeColor h3 {
    color: #0a71f0
}

body.viva .nav-tabs li.active {
    background-color: #0a71f0
}

body.viva .changeColor h3 {
    color: #0a71f0
}

body.freeze .nav-tabs li.active {
    background-color: #0a71f0
}

body.freeze .changeColor h3 {
    color: #0a71f0
}

body.neograft .nav-tabs li.active {
    background-color: #0a71f0
}

body.neograft .changeColor h3 {
    color: #0a71f0
}

body.fiore .nav-tabs li.active {
    background-color: #0a71f0
}

body.fiore .changeColor h3 {
    color: #0a71f0
}

body.epileve .nav-tabs li.active {
    background-color: #0a71f0
}

body.epileve .changeColor h3 {
    color: #0a71f0
}

body.heal .nav-tabs li.active {
    background-color: #0a71f0
}

body.heal .changeColor h3 {
    color: #0a71f0
}

body.bliss .nav-tabs li.active {
    background-color: #0a71f0
}

body.bliss .changeColor h3 {
    color: #0a71f0
}

.setTabColor .freeze.active {
    background-color: #0a71f0
}

.setTabColor .legacy.active {
    background-color: #0a71f0 !important
}

.setTabColor .versa.active {
    background-color: #0a71f0
}

.setTabColor .viva.active {
    background-color: #0a71f0
}

.setTabColor .velocity.active {
    background-color: #0a71f0
}

.setTabColor .neograft.active {
    background-color: #0a71f0
}

.setTabColor .fiore.active {
    background-color: #0a71f0
}

.setTabColor .epileve.active {
    background-color: #0a71f0
}

.setTabColor .heal.active {
    background-color: #0a71f0
}

.setTabColor .bliss.active {
    background-color: #0a71f0
}

.btn-white:hover,
.btn-white:focus {
    background-color: #737373
}

.results .carousel-control .screen-reader-text,
.testimonialCaro .carousel-control .screen-reader-text {
    color: #000
}

.article_gallery .carousel-control .sr-only {
    color: #000
}

@media only screen and (max-width:1023px) {
    .smallmenu button {
        background-color: inherit;
        font-size: .87em
    }
}

@media(max-width:1500px) and (min-width:1024px) {
    .topImage.newsTop {
        background-position: -457px 0
    }
}

.lang_he .navbar-nav {
    text-align: left;
    float: left
}

.lang_he .socialIconsTop {
    text-align: left;
    padding-left: 25px
}

.lang_he .region {
    right: auto;
    left: 25px
}

.lang_he .topFooter .col-md-9.id1.first {
    padding-top: 70px
}

@media screen and (max-width:767px) {
    .lang_he .topFooter .col-md-9.id1.first {
        padding-top: 0
    }
}

.lang_he .topFooter .id2.last {
    padding-top: 0
}

@media only screen and (max-width:1023px) {
    .lang_he .footerlinks .col-md-2 {
        width: 16%
    }
}

@media only screen and (max-width:991px) and (min-width:700px) {

    .lang_he .reverse-col-md>.container>.row,
    .lang_he .reverse-col-md>.container-fluid>.row {
        flex-direction: row-reverse
    }
}

@media only screen and (max-width:699px) {

    .lang_he .reverse-col-md>.container>.row,
    .lang_he .reverse-col-md>.container-fluid>.row {
        flex-direction: column-reverse
    }
}

@media only screen and (max-width:991px) {

    .lang_he .reverse-col-md>.container>.row,
    .lang_he .reverse-col-md>.container-fluid>.row {
        display: flex;
        flex-wrap: wrap
    }
}

@media only screen and (max-width:1023px) {
    .lang_he .logoSlice .col-md-7 {
        right: auto;
        left: 35px
    }
    .lang_he .logoSlice .col-md-7 {
        right: auto;
        left: 35px
    }
}

@media only screen and (max-width:767px) {
    .lang_he .topFooter .col-md-9 .text-left {
        width: 100%;
        text-align: center
    }

    .lang_he .topFooter .id2.first {
        padding-top: 10px
    }

    .lang_he .topFooter .id2.last {
        padding-top: 0
    }

    .lang_he .logoSlice .col-md-7 {
        right: auto;
        left: 0
    }

    .lang_he .socialIconsTop {
        padding-bottom: 0;
        padding-top: 25px
    }
}

.lang_he .contactIcon {
    float: right
}

.lang_he .contactText {
    padding-left: 0;
    padding-right: 50px
}

.lang_he .addressTable table td img {
    left: auto;
    right: 0
}

.lang_he .results .carousel-control .glyphicon-chevron-left,
.lang_he .results .carousel-control .icon-prev {
    right: 30%
}

.lang_he .results .carousel-control .glyphicon-chevron-right,
.lang_he .results .carousel-control .icon-next {
    right: auto !important
}

.lang_he .results .glyphicon-chevron-left:before,
.lang_he .results .glyphicon-chevron-right:before,
.lang_he .custsomprevNext .carousel-control img {
    transform: rotate(180deg)
}

.lang_he .regions table td img {
    margin-right: 0;
    margin-left: 15px
}

.shiftUp {
    margin-top: -340px
}

.line-bottom {
    border-bottom: 1px solid #d2d2d2
}

.shiftUp .container>.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline
}

#supportingfacts>.container>.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.numbers-block {
    padding-bottom: 75px
}

@media screen and (min-width:992px) {
    .numbers-block.no-padding {
        padding-bottom: 0
    }
}

@media screen and (min-width:992px) and (max-width:1230px) {
    .shiftUp.custom-bottom-align {
        margin-top: -280px
    }

    .shiftUp.custom-bottom-align .container>.row {
        align-items: flex-end
    }
}

.shiftUp .col-md-5 p {
    text-align: center;
    margin-bottom: 0
}

.numbers-block ul {
    padding-left: 0;
    list-style: none;
    margin-top: 25px
}

.numbers-block ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 10px;
    border-bottom: 1px solid #d2d2d2;
    font-size: 15px
}

.numbers-block ul li:last-child {
    border-bottom: 1px solid transparent
}

.numbers-block ul li img {
    margin-right: 20px
}

.numbers-block ul li span:nth-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    min-width: 50px
}

.numbers-block ul li span:nth-child(2) {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 90%;
    flex: 1 1 90%
}

.love-neografters-glid-slice .row {
    display: flex;
    flex-wrap: wrap
}

.love-neografters-glid-slice .row:before {
    display: none
}

.love-neografters-glid-slice .col-md-4 {
    background-color: #f5f5f5;
    border-bottom: 7px solid #fff;
    border-right: 7px solid #fff;
    padding: 40px 20px
}

.love-neografters-glid-slice .col-md-4:last-child,
.love-neografters-glid-slice .col-md-2:last-child {
    border-right: none
}

.love-neografters-glid-slice .col-md-2 {
    background-color: #d0d0d0;
    border-bottom: 7px solid #fff;
    border-right: 7px solid #fff
}

@media screen and (max-width:992px) {
    .love-neografters-glid-slice .col-md-4 {
        border-right: none;
        width: 100%
    }

    .love-neografters-glid-slice .col-md-2 {
        display: none
    }
}

@media screen and (min-width:1024px) {
    .logoSlice {
        padding-bottom: 0
    }
    .stickyLogoSlice{
        width: 100%;
        height: 17%;
    }
}

.logoSlice .navbar-default {
    border-bottom: 0;
    margin-bottom: 0
}

.logoSlice .navbar-nav>li {
    padding-bottom: 22px;
    border-bottom: 3px solid transparent
}

.fix .navbar-nav>li {
    padding-bottom: 6px;
    border-bottom: 3px solid transparent
}

.logoSlice .navbar-nav>li.aesthetic-toggle:hover,
.logoSlice .navbar-nav>li.aesthetic-toggle:focus,
.fix .navbar-nav>li.aesthetic-toggle:hover,
.fix .navbar-nav>li.aesthetic-toggle:focus,
.logoSlice .navbar-nav>li.medical-toggle:hover,
.logoSlice .navbar-nav>li.medical-toggle:focus,
.fix .navbar-nav>li.medical-toggle:hover,
.fix .navbar-nav>li.medical-toggle:focus {
    border-bottom: 3px solid #fff
}

.aestheticmenu-wrapper,
.medicalmenu-wrapper,
.devicemenu-wrapper,
.treatmentmenu-wrapper {
    display: none
}

.aesthetic-toggle,
.medical-toggle,
.device-toggle,
.treatments-toggle {
    position: relative
}

.megamenu-wrapper {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, .7);
    background-image: url(./images/menu_bg.png);
    color: #fff;
    min-width: 85vw;
    min-height: 450px;
    transform: translate(-50%, 0);
    left: 50%;
    top: calc(100% + 3px);
    z-index: 4
}

body.location_en-gl .megamenu-wrapper {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, .7);
    background-image: url(./images/menu_bg.png);
    color: #fff;
    min-width: 90vw;
    min-height: 450px;
    transform: translate(-50%, 0);
    left: 50%;
    top: calc(100% + 3px);
    z-index: 4
}

.aesthetic-toggle:hover .megamenu-wrapper,
.medical-toggle:hover .megamenu-wrapper,
.device-toggle:hover .megamenu-wrapper,
.treatments-toggle:hover .megamenu-wrapper {
    display: block
}

#header .megamenu-wrapper .dropdown-menu,
.fix .megamenu-wrapper .dropdown-menu {
    display: block;
    position: relative;
    background-image: none;
    background-color: transparent
}

#header .megamenu-wrapper .dropdown-menu[data-level='2'],
.fix .megamenu-wrapper .dropdown-menu[data-level='2'] {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap
}

#header .megamenu-wrapper .dropdown-menu[data-level='3'],
.fix .megamenu-wrapper .dropdown-menu[data-level='3'] {
    float: none;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 15px 0
}

#header .megamenu-wrapper .dropdown-menu[data-level='4'],
.fix .megamenu-wrapper .dropdown-menu[data-level='4'] {
    padding: 0 10px
}

#header .megamenu-wrapper .dropdown-menu[data-level='4'] li>a,
.fix .megamenu-wrapper .dropdown-menu[data-level='4'] li>a {
    padding: 5px 15px;
    font-weight: 400
}

#header .megamenu-wrapper .dropdown-menu li>a,
.fix .megamenu-wrapper .dropdown-menu li>a {
    padding: 10px 15px;
    white-space: normal
}

body.location_en-gl #header .megamenu-wrapper .dropdown-menu li>a,
body.location_en-gl .fix .megamenu-wrapper .dropdown-menu li>a {
    padding: 10px 7px;
    white-space: normal
}

.megaMenu-cta {
    text-align: left;
    border-left: 1px solid #959595;
    margin-right: -15px
}

.cta-text-group {
    padding: 35px 25px;
    font-size: 12px
}

.cta-text-group .subtitle {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px
}

.megaMenu-cta img {
    max-width: 100%;
    height: auto
}

@media screen and (max-width:1199px) {
    .megaMenu-cta {
        display: none
    }
}

#smallmenu .has-dropdown>a:after {
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    width: 10px;
    height: 10px;
    display: inline-block;
    transform: rotate(45deg);
    margin-bottom: 3px;
    margin-left: 10px
}

#redirectconfirm-modal .modal-body,
#redirectconfirm-modal .modal-footer {
    text-align: center;
    background-color: #fff
}

#redirectconfirm-modal .modal-header,
#redirectconfirm-modal .modal-footer {
    border: none
}

#redirectconfirm-modal .modal-footer {
    padding-bottom: 55px
}

#redirectconfirm-modal .modal-header {
    padding: 30px
}

#redirectconfirm-modal .close {
    color: #000
}

#redirectconfirm-modal .modal-body {
    padding: 15px 50px
}

#redirectconfirm-modal .logo-wrapper {
    padding-bottom: 50px
}

.redirect-modal .modal-dialog {
    max-width: 1200px
}

.redirect-modal .modal-content {
    background-image: url(./images/vc-geo-popup.jpg);
    background-position: left;
    background-size: contain
}

.redirect-modal .modal-content {
    padding-left: 315px
}

.redirect-modal h2 {
    margin-bottom: 50px
}

.redirect-modal hr {
    border-top: 2px solid #888;
    margin: 50px 0
}

.redirect-modal .modal-body {
    padding: 100px calc(10% + 50px) 100px 50px
}

.redirect-modal span.country {
    text-decoration: underline;
    font-weight: 700
}

@media only screen and (max-width:992px) {
    .redirect-modal .modal-body {
        padding: 50px
    }

    .redirect-modal .modal-content {
        padding-left: 0;
        text-align: center;
        background: 0 0
    }
}

@media only screen and (max-width:1199px) {
    .lang_he .regions table td img {
        margin-right: 0
    }
}

@media screen and (max-width:991px) {
    .shiftUp {
        margin-top: 0
    }

    .shiftUp .container>.row {
        display: block
    }

    .numbers-block {
        padding-top: 50px;
        padding-bottom: 50px
    }
}

@media only screen and (max-width:767px) {
    .lang_he .regions td {
        text-align: right
    }
}

@media(min-width:768px) {
    .lang_he .navbar-header {
        float: right
    }
}

@media only screen and (max-width:1023px) {
    .stickyLogoSlice{
        width: 100%;
        height: 15%;
    }
    .lang_he .fix .topmenu.btn {
        right: auto;
        left: 0;
        top: 3px
    }

    .lang_he .fix .navbar-brand {
        padding-left: 20px;
        float: right
    }

    .region {
        display: none
    }

    #smallmenu .region {
        display: block;
        right: 20%;
        left: 20%;
        width: 88px;
        margin: auto
    }
}

html:lang(ja-jp) .redirect-modal .modal-content {
    background-image: none;
    padding-left: 0
}

html:lang(ja-jp) .redirect-modal .modal-body {
    padding: 100px 50px
}

html:lang(en-ca) .menu-update,
html:lang(en-us) .menu-update,
html:lang(en) .menu-update {
    position: relative
}

html:lang(en-ca) .menu-update .cta-text-group,
html:lang(en-us) .menu-update .cta-text-group,
html:lang(en) .menu-update .cta-text-group,
html:lang(fr-ca) .menu-update .cta-text-group,
html:lang(en-gl) .menu-update .cta-text-group,
html:lang(en-uk) .menu-update .cta-text-group,
html:lang(en-ie) .menu-update .cta-text-group,
html:lang(en-id) .menu-update .cta-text-group,
html:lang(en-au) .menu-update .cta-text-group,
html:lang(en-sg) .menu-update .cta-text-group,
html:lang(en-za) .menu-update .cta-text-group {
    background: #2f2f2f;
    height: 100%
}

html:lang(en-ca) .menu-update .megaMenu-cta,
html:lang(en-us) .menu-update .megaMenu-cta,
html:lang(en) .menu-update .megaMenu-cta,
html:lang(fr-ca) .menu-update .megaMenu-cta,
html:lang(en-gl) .menu-update .megaMenu-cta,
html:lang(en-uk) .menu-update .megaMenu-cta,
html:lang(en-ie) .menu-update .megaMenu-cta,
html:lang(en-id) .menu-update .megaMenu-cta,
html:lang(en-au) .menu-update .megaMenu-cta,
html:lang(en-sg) .menu-update .megaMenu-cta,
html:lang(en-za) .menu-update .megaMenu-cta {
    border-left: none !important;
    margin-right: 0;
    height: 100%;
    display: flex;
    flex-direction: column
}

html:lang(en-ca) .menu-update .megamenu-wrapper,
html:lang(en-us) .menu-update .megamenu-wrapper,
html:lang(en) .menu-update .megamenu-wrapper,
html:lang(fr-ca) .menu-update .megamenu-wrapper {
    background: 0 0
}

html:lang(en-ca) .menu-update-flex .row,
html:lang(fr-ca) .menu-update-flex .row,
html:lang(en) .menu-update-flex .row,
html:lang(en-us) .menu-update-flex .row,
html:lang(en-gl) .menu-update-flex .row,
html:lang(en-uk) .menu-update-flex .row,
html:lang(en-ie) .menu-update-flex .row,
html:lang(en-id) .menu-update-flex .row,
html:lang(en-au) .menu-update-flex .row,
html:lang(en-sg) .menu-update-flex .row,
html:lang(en-za) .menu-update-flex .row {
    display: flex
}

html:lang(en-ca) .menu-update .col-lg-8,
html:lang(en-us) .menu-update .col-lg-8,
html:lang(en) .menu-update .col-lg-8 {
    background: #000
}

html:lang(en-ca) .menu-update .col-lg-4,
html:lang(en-us) .menu-update .col-lg-4,
html:lang(en) .menu-update .col-lg-4,
html:lang(en-gl) .menu-update .col-lg-4,
html:lang(fr-ca) .menu-update .col-lg-4 {
    padding: 0;
    display: flex
}

html:lang(en-us) .aestheticmenu-wrapper.menu-update .megaMenu-cta img,
html:lang(en) .aestheticmenu-wrapper.menu-update .megaMenu-cta img {
    height: 100%;
    object-fit: cover
}

html:lang(en-us) .medicalmenu-wrapper.menu-update .megaMenu-cta img,
html:lang(en) .medicalmenu-wrapper.menu-update .megaMenu-cta img {
    height: 100%;
    object-fit: cover
}

html:lang(en-ca) .menu-update .row,
html:lang(en-us) .menu-update .row,
html:lang(en) .menu-update .row {
    background: #000
}

html:lang(en-ca) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2'],
html:lang(en-us) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2'],
html:lang(en) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2'] {
    flex-wrap: wrap
}

html:lang(en-ca) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2']>li:not(.last),
html:lang(en-us) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2']>li:not(.last),
html:lang(en) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2']>li:not(.last) {
    width: 33%
}

@media screen and (max-width:1200px) {

    html:lang(en-ca) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2']>li.last,
    html:lang(en-us) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2']>li.last,
    html:lang(en) .menu-update .megamenu-wrapper .dropdown-menu[data-level='2']>li.last {
        display: none
    }

    html:lang(en-ca) .menu-update .col-lg-4,
    html:lang(en-us) .menu-update .col-lg-4,
    html:lang(en) .menu-update .col-lg-4,
    html:lang(fr-ca) .menu-update .col-lg-4 {
        display: none
    }
}

.aesthetic-wrapper>.dropdown-menu[data-level='2'],
.medical-menu>.dropdown-menu[data-level='2'],
.device-menu>.dropdown-menu[data-level='2'],
.treatments-menu>.dropdown-menu[data-level='2'] {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around
}

body.location_en-gl .device-menu #bootstrapmenu-_ a.dropdown-toggle {
    padding: 10px 7px !important
}

body.location_en-gl .device-menu a.dropdown-toggle:hover,
body.location_en-gl .medical-menu a.dropdown-toggle:hover {
    cursor: default !important;
    background-color: initial !important
}

.aesthetic-wrapper .dropdown[data-level='2'] {
    width: 25%
}

.medical-menu .dropdown[data-level='2'] {
    width: 33%
}

.device-menu .dropdown[data-level='2'] {
    width: 20%
}

.treatments-menu .dropdown[data-level='2'] {
    width: 25%
}

html:lang(en-ca) #bootstrapmenu-_ a.dropdown-toggle:hover {
    cursor: default;
    background-color: unset !important;
    text-decoration: none;
    color: #fff
}

html:lang(en-ca) #bootstrapmenu-_ a.dropdown-toggle {
    background-color: unset !important;
    padding-top: 20px !important
}

html:lang(en-ca) #bootstrapmenu-_ a.dropdown-toggle:focus {
    background: unset !important
}

html:lang(en-us) #bootstrapmenu-_ a.dropdown-toggle:hover {
    cursor: default;
    background-color: unset !important;
    text-decoration: none;
    color: #fff
}

html:lang(en-us) #bootstrapmenu-_ a.dropdown-toggle {
    background-color: unset !important;
    padding-top: 20px !important
}

html:lang(en-us) #bootstrapmenu-_ a.dropdown-toggle:focus {
    background: unset !important
}

body.location_en-gl #bootstrapmenu-_ a.dropdown-toggle:hover {
    cursor: default;
    background-color: unset !important;
    text-decoration: none;
    color: #fff
}

body.location_en-gl #bootstrapmenu-_ a.dropdown-toggle {
    background-color: unset !important;
    padding-top: 20px !important
}

body.location_en-gl #bootstrapmenu-_ a.dropdown-toggle:focus {
    background: unset !important
}

html:lang(en-ca) #header .aesthetic-wrapper .dropdown-menu[data-level='2'],
html:lang(en-ca) #header .aesthetic-wrapper .dropdown-menu[data-level='2'] {
    padding-bottom: 0
}

#one-star-ref,
#two-star-ref,
#three-star-ref,
#four-star-ref {
    color: #000
}

#one-star-ref:hover,
#two-star-ref:hover,
#three-star-ref:hover,
#four-star-ref:hover {
    text-decoration: none
}

#pardotFrame {
    border: 0
}

.column-border {
    border: 2px solid #f5f5f5;
    padding: 25px;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column
}

.column-border .title {
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 600
}

.column-border .info {
    line-height: 34px
}

.column-border .small {
    font-size: 12px;
    font-weight: 600
}

.column-border .phone {
    font-size: 16px
}

.column-border .btn {
    margin-bottom: 20px
}

.column-border a {
    font-weight: 500
}

.map-container .col-md-8 {
    height: 508px;
    border-top: 2px solid#cecece;
    border-left: 2px solid #cecece;
    border-bottom: 2px solid #cecece;
    padding-left: 0
}

.map-container .col-md-4 {
    height: 508px;
    border-top: 2px solid #cecece;
    border-right: 2px solid #cecece;
    border-bottom: 2px solid #cecece;
    padding-right: 15px
}

@media screen and (min-width:768px) {
    .carousel-with-thumbnails {
        margin-top: 150px
    }

    .carousel-with-thumbnails .carousel-indicators {
        top: -150px
    }

    .carousel-with-thumbnails .carousel-indicators>li>img {
        opacity: .5;
        transition: all ease .5s
    }

    .carousel-with-thumbnails .carousel-indicators>li.active>img {
        opacity: 1
    }

    .carousel-with-thumbnails .carousel-indicators>li {
        width: 130px;
        height: auto;
        text-indent: 0
    }
}

@media screen and (max-width:767px) {
    .carousel-with-thumbnails .carousel-indicators>li>img {
        display: none
    }
}

@media screen and (max-width:600px) {
    .map-container .col-md-4 {
        height: 358px
    }
}

@media screen and (max-width:992px) {
    .column-border {
        min-height: 350px;
        margin-bottom: 30px;
        height: unset
    }

    .contactForm-new {
        padding: 0;
        padding-top: 50px
    }
}

.map-border span {
    font-weight: 600
}

.expertModal .modal-dialog {
    max-width: 900px
}

.expertModal-open .modal-body {
    background: 0 0
}

.sectionHeader-blue h3 {
    border-bottom: 1px solid #0a71f0
}

.text-center {
    text-align: center
}

.skinTabs .nav-tabs>li.active>a,
.skinTabs .nav-tabs>li.active>a:focus,
.skinTabs .nav-tabs>li.active>a:hover {
    background-color: #096be1;
    color: #fff
}

.skinTabs .nav-tabs>li {
    border-radius: 4px 4px 0 0
}

.skinTabs .tab-content table {
    margin: 25px 0 75px;
    width: 100%
}

.skinTabs .tab-content table td {
    vertical-align: top;
    padding: 10px 0
}

.skinTabs .tab-content .leftPanel {
    width: 40%
}

.skinTabs .tab-content .middlePanel {
    width: 5%
}

.skinTabs .tab-content .rightPanel {
    width: 55%
}

.glowSkin .slider-single-image {
    max-width: unset
}

.glowSkin .tab-content {
    padding: 25px 0 !important
}

.glowSkin .skinTabs .tab-content table td {
    vertical-align: inherit !important
}

.glowSkin .skinTabs .tab-content {
    min-height: 460px
}

@media only screen and (min-width:1026px) {
    .fullWidthTabs .skinTabs .tab-content {
        width: 970px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto
    }
}

@media only screen and (min-width:1200px) {
    .fullWidthTabs .skinTabs .tab-content {
        width: 1170px;
        max-width: 1170px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto
    }
}

.skinTabs .nav-tabs {
    background-color: #e7e5e5;
    box-shadow: 0 2px 4px 0 rgba(226, 223, 227, .5)
}

.skinTabs #bootstraptab {
    background: #fff
}

@media only screen and (max-width:767px) {
    .fullWidthTabs.skin .nav-tabs>li {
        border-bottom: 1px solid #999;
        width: 100%
    }
}

@media only screen and (min-width:1024px) {
    .skin-carousel.slider-container {
        height: 550px
    }
}

@media only screen and (max-width:1199px) {
    .fullWidthTabs.skin .nav.nav-tabs>li>a {
        padding: 20px 15px
    }
}

.testimonial-container {
    display: flex;
    min-height: 254px
}

.testimonial-container .col-1 {
    padding-right: 15px;
    width: 30%
}

.testimonial-container .col-2 {
    width: 70%
}

.venus-williams-btn {
    color: #c8102e;
    background: #fff;
    border: 1px solid #9b9b9b
}

.career-modal .contents {
    text-align: center
}

img.responsive {
    max-width: 100%;
    height: auto
}

body.product-template-page.bliss .topImage a.btn {
    background: linear-gradient(#da1a32, #b11125)
}

body.product-template-page.bliss .subnav {
    background: linear-gradient(#da1a32, #b11125)
}

@media(max-width:991px) {

    .subnav .col-md-3,
    .subnav .col-md-7 {
        text-align: center
    }
}

body.product-template-page .subnav a {
    color: #fff;
    margin: 17px 10px;
    display: inline-block;
    font-weight: 600
}

body.product-template-page.bliss .subnav a.btn {
    background-color: #fff;
    color: #dd1a32;
    margin: 8px 0
}

.subnav .wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap
}

#stuckNav {
    position: fixed;
    top: 53px;
    z-index: 1050;
    width: 100%
}

@media screen and (max-width:1100px) {
    #stuckNav {
        display: none
    }
}

.product-template-page hr {
    margin-top: 10px;
    margin-bottom: 10px
}

@media only screen and (max-width:1023px) {
    .product-template-page .productHeader.topImage:before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top right, #fff, #fff);
        -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#1471da, endColorstr=#1C85FB)';
        opacity: .5
    }

    .evaluation-slice-bg {
        background-position: center
    }
}

body.product-template-page.bliss hr {
    border-top: 1px solid #dd1a32
}

.product-template-page .top-border-primary {
    position: relative;
    margin-top: 10px
}

.product-template-page .top-border-primary:before {
    position: absolute;
    top: -8px;
    content: '';
    width: 56px
}

.product-template-page.bliss .top-border-primary:before {
    border-top: 5px solid #dd1a32
}

.specs-table td {
    padding: 15px 50px 15px 10px;
    position: relative
}

@media only screen and (max-width:767px) {
    .specs-table td {
        padding: 15px 10px
    }
}

.specs-table td:before {
    position: absolute;
    top: 11px;
    content: '';
    width: 28px;
    border-top: 1px solid #7c7c7c
}

.specs-table p {
    color: #7c7c7c
}

.specs-table p strong {
    color: #000;
    text-transform: uppercase
}

@media only screen and (min-width:768px) {
    .results .clinical-results {
        width: 50%
    }
}

.product-template-page .title-h6 {
    text-transform: uppercase
}

.product-template-page .results .col-md-5 {
    padding: 0;
    margin-top: 6.2%
}

.product-template-page .results .col-md-7 {
    background-color: #f4f4f4;
    display: flex;
    flex-wrap: wrap;
    padding: 100px 70px;
    min-height: 548px
}

.product-template-page .results .carousel-control.left,
.product-template-page .results .carousel-control.right {
    bottom: 0;
    top: 90%;
    border: none
}

.product-template-page .results .carousel-control.left {
    left: 42.5%
}

.product-template-page .results .carousel-control.right {
    left: 48%
}

.product-template-page .testimonialCaro .caroPic {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap
}

.product-template-page .testimonialCaro .caroPic .text-right {
    padding: 15px
}

.product-template-page .testimonialCaro .carousel-inner {
    min-height: auto
}

.product-template-page .testimonialCaro .text-center {
    font-size: 20px
}

.product-template-page .testimonialCaro .caro-jobtitle {
    color: #c6c6c6
}

.flex-row>section>.container>.row {
    display: flex;
    flex-wrap: wrap
}

.modalities {
    background-image: url(./images/applicator_bg.jpg);
    background-size: cover;
    padding: 100px 0;
    background-color: #1d2f35
}

.modalities,
.modalities h2 {
    color: #fff
}

.specs {
    background-image: url(./images/ripple_bg.png);
    background-size: cover;
    padding: 50px 0;
    background-position: 0 135px;
    background-repeat: no-repeat
}

.features h2 {
    color: #fff
}

@media only screen and (min-width:992px) {
    .specs .applicator img {
        height: 800px;
        width: auto
    }
}

.features-collapse .panel {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: unset
}

.features-collapse .panel-default>.panel-heading {
    color: #fff;
    background-color: transparent;
    border-color: #ddd;
    padding: 20px 15px
}

@media(max-width:767px) {
    .features-collapse .panel-default>.panel-heading {
        padding: 20px 0
    }

    .modalities>section .container {
        padding-left: 0;
        padding-right: 0
    }

    .modalities>section>.container {
        padding-left: 40px;
        padding-right: 40px
    }
}

@media only screen and (max-width:670px) {
    .product-template-page .clinical-results {
        padding-left: 40px;
        padding-right: 40px
    }
}

.features-collapse .panel-default:last-child {
    border-color: transparent
}

.features-collapse .panel-title>a:focus {
    color: inherit;
    outline: 1px dashed #fff !important;
    font-size: 17px
}

.features-collapse .panel-title>a {
    display: flex;
    align-items: baseline;
    min-height: 45px
}

.features-collapse .panel-title {
    background-image: url(./images/chevron-grey.png);
    background-position: center right;
    background-repeat: no-repeat;
    position: relative;
    display: block;
    background-size: 25px
}

.features-collapse .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top: none;
    margin-left: calc(15px + 43px)
}

.features-collapse .panel-title>a>span {
    padding-bottom: 6px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 29px;
    border: 1px solid #fff;
    margin-right: 15px;
    display: flex;
    width: 43px;
    height: 43px;
    justify-content: center;
    align-items: center
}

body.product-template-page.bliss .features-collapse .panel-title>a>span {
    background-color: #dd1a32
}

.features-collapse .panel-title>a:hover {
    text-decoration: none;
    font-size: 17px
}

.evaluation-slice-bg {
    background-image: url(./images/download_bg.jpg);
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover
}

.expert-slice-bg {
    background-image: url(./images/expert_bg.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 70% auto
}

@media only screen and (max-width:767px) {
    .expert-slice-bg {
        background-image: none;
        text-align: center
    }
}

map#chart-map area {
    cursor: pointer
}

.chart-modals button.close {
    margin: 10px;
    opacity: 1
}

.chart-modals h4 {
    font-weight: 600;
    margin-bottom: 20px
}

.chart-modals h4 .highlight {
    color: #dd1a32
}

.chart-modals .wrapper .modal-body {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center
}

@media only screen and (min-width:1023px) {
    .chart-modals .modal.in .modal-dialog {
        width: auto;
        max-width: unset;
        position: absolute;
        float: left;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        transition: transform 0s
    }

    .chart-modals .modal {
        background-image: url(./images/gradient.jpg);
        background-repeat: no-repeat;
        background-size: cover
    }

    .chart-modals .fade {
        transition: none
    }

    .chart-modals .modal-content {
        border-radius: 1000px;
        border: 3px solid #dd1a32
    }

    .chart-modals .wrapper {
        position: relative;
        width: 100%;
        padding-top: 100%;
        border-radius: 1000px
    }

    .chart-modals .wrapper .modal-body {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 1000px;
        padding: 15%
    }
}

.ceoslice .article_title,
.ceoslice .article_meta_designation {
    font-weight: 600;
    margin-top: 5px
}

@media only screen and (min-width:1024px) {

    .ceoslice .article_html,
    .ceoslice .article_title,
    .ceoslice .article_meta_designation {
        width: calc(66% - 25px);
        float: right
    }
}

@media only screen and (min-width:1200px) {
    .ceoslice .article_title {
        margin-top: 35px
    }
}

.branding-bg {
    background-image: url(./images/unvelilning_bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    padding: 300px 0 100px;
    background-size: cover;
    position: relative
}

.branding-bg .col-md-3 {
    position: absolute;
    right: 40px;
    top: 0;
    width: auto
}

@media only screen and (min-width:1023px) {
    .branding-bg {
        background-image: url(./images/unvelilning_bg.jpg);
        padding: 100px 0 50px
    }
}