body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    line-height: 26px;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif!important;
     max-width: 100%;
     overflow-x: hidden;
    
}


#header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1051;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif!important;
     max-width: 100%;
     overflow-x: hidden;
}

._uppertop {
    color: #fff;
    background-color: #616161;
    font-size: 13px;
    font-family: 'Raleway', sans-serif!important;
    margin: 0;
    padding: 0.938rem 0rem;
    font-weight: 600;
    letter-spacing: normal;
    width: 100%;
    height: 4.25rem;
    
    
}
.container-fluid{
  padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}
.rowcnt2{
  display: flex;
    align-items: center;
    margin-right: -15px;
    margin-left: -15px;

}
._rowsm2{
    padding-left: 9rem;
}

.imgicon {
    width: 16px;
    height: 16px;
}

.mobilenoclass {
    color: #fff;
    margin: 0;
    letter-spacing: normal;
}

._searchbox {
    background-color: #fff;
    color: #0961ce;
    margin: 0;
    padding: 0rem 0rem 0rem 0.625rem;
    width: 16.875rem;
    border: 1px solid #ccc;
    border-radius: 20px;
    height: 2.1875rem;
}

.searchWrapper-variantA {
    display: flex;
    align-items: center;
}

.search-image {
    display: flex;
    align-items: center;
    margin-right: 0.625rem;
    cursor: pointer;
}

.cnt2 {
    display: flex;
    align-items: center;
    
}

.contact-info {
    display: flex;
    align-items: center;
    margin-right: 0.563rem;
}

.tel {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 19.5px;
    vertical-align: baseline;
    letter-spacing: normal;
}
._dropglob{
    transform: rotate(46deg);
    border: solid white;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
    margin-bottom: 3%;
    
    
    
    
    
}
.newsmeimgclass{
    line-height:26px;
}

.dropdiv {
    background-color: #616161;
    border: 0;
    text-transform: uppercase;
    padding-right: 1.688rem;
    padding-bottom: 0;
    margin-right: 0.375rem;
    display: inline-block;
    box-shadow: none;
    color: #fff !important;
    width: auto;
    height: auto;
    border-style: dotted;
    border-color: white;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    border-style: dotted;
    border-color: white;
    padding: 10  ;
    background-color: #616161;
    display: none;
}

.show .dropdown-menu {
    display: block;
}

.xselectLang {
    margin-left: 0.625rem;
margin-right: 0.625rem;
    color: #fff;
    font-weight: 400;
}

._normline {
}

.extra-cell ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.extra-cell ul li {
    display: inline-block;
}

.extra-cell ul li .btn-link {
    padding: 0 0 0 1.25rem; 
    margin-left: 0.625rem;
    position: relative;
    cursor: pointer;
}

.extra-cell ul li .btn-link:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: #000;
    left: 0;
    top: 0;
    opacity: 0.1;
}

.extra-cell ul li a,
.extra-cell ul li .btn-link {
    padding: 0 0.5714rem;
    color: #000;
}

._rowsm{
    
    float: right;
    position: relative;
    min-height: 0.0625rem; 
    padding-right: 0.9375rem; 
    padding-left: 0.9375rem;;
    color: #fff;
    word-spacing: 0;
    margin: 0;
    padding: 0
}

._topImage{
    background-image: url(/src/component/images/bgimage.jpg);
    transition: padding-top .3s ease;
    heigh: auto;
    min-height: 733px;
    
    background-size: cover;
    background-position: top right;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center !important;
    
}
.bigbgmaindiv{
    /* margin-top: 1rem; */
}
.logoSlice{
    background-color: rgba(0,0,0,.7);
   
 
    padding: 25px 0px 0px;
    transition: height 0.3s ease;
    
    
}
.stickyLogoSlice {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 68px; 
  
  padding: 10px 0;
  z-index: 1000; 
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); 
}
.logoSlicontain{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.navdiv {
    display: flex;
    flex-direction: row;
    padding-left: 41%;
  }
  
  ._navcon {
    width: 100%;
  }
  
  ._navbar_deafult {
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 1px;
    min-height: 30px;
    margin-left: 49%;
  }
  
  .nav-container {
    padding: 0.625rem 0;
    display: flex;
  flex-direction: row; /* Change to row for left-to-right alignment */
  align-items: center;
  width: 100%;
  }
  
  .nav.navbar-nav {
    display: flex;
  flex-direction: row; /* Change to row for left-to-right alignment */
  align-items: center;
  margin: 0;
  float:right;
  }
  
  ._navbarlist {
    margin: 0;
    padding: 0 0.5rem;
    
  }
  
  ._navlistaaa {
    
    font-size: 14.944px;
    text-transform: uppercase;
    color: white;
    cursor: pointer; 
    display: block;
    margin:0px;
padding:17px 0px 17px 15px;
line-height:13px;
letter-spacing:1px;
  }
  ._navlistaaa:hover{
    color: #616161; 
    
  }
  
  .nav.flex-column .nav-link {
    font-size: 0.934em;
    text-transform: uppercase;
    color: white !important;
    
    display: block;
  }
  .searchinput{
    border-width: 1px;
    border-style: solid;
    width: 100%;
    padding: 15px 15px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font-size: 13px;
  }
  .gdlr-space{
    
  }
.top-social-wrapper {
    float: left;
    margin-left: 22px;
    gap: 10px;
    line-height: 20px;
    display: flex;
}

.top-social-wrapper .social-icon {
    float: left;
    margin-left: 10px;
    max-width: 14px;
}

.top-navigation-wrapper a {
    color: #f3f3f3;
}

.aesthetic {
    background-image: url(./images/doctor.png);
    background-size: cover;
    padding: 150px 0;
}

.navbar-brand {
    display: flex;
    align-items: end;
    justify-content: end;
}

.col-6 {
    width: 50%;
}

.rows {
    display: flex;
}

@media only screen and (max-width: 1023px) {
    .rows {
        display: block;
    }
    
    .stickyLogoSlice {
        width: 100%;
    }
}

@media only screen and (max-width: 1023px) {
.stickyLogoSlice{
    width: 100%;
}
}
@media only screen and (max-width: 1023px) {
    .col-6 {
        width: 100%;
    }
}

h2.feature-h2 {
    padding: 100px 30px 0px 0px;
}

p.feature-p {
    padding: 50px 30px 0px 0px;
    color: black;
}

.col-3 {
    width: 25%;
}


/* Yogesh css */

.aa {
    display: inline-flex;
    list-style-type: none;
    color: #fff;
}

.aa li {
    list-style: none;
    display: inline-block;
    padding: 10px 10px;
    font-size: 20px;
}

@media (min-width: 0px) and (max-width: 900px) {
    .aa {
        display: contents;
        color: #fff;
    }
    .subjkkbutton{
        padding-left: 27rem;
    }
    .stickyLogoSlice {
        width: 100%;
    }
}

/* Desktop styles */
.containers {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #ebe5d9;
}

.resultrow {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.col-3 {
    flex: 0 0 calc(25% - 20px);
    padding: 10px;
    box-sizing: border-box;
}

.group_img {
    max-width: 100%;
    height: auto;
    border: 2px solid black;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.Menubttonhead{
    font-size: 10px;
    font-weight: 800;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    background-color: #555556;
    border: 1px solid #fff;
    border-radius: 22px;
    padding: 15px 25px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 6%;
}

/* Mobile styles */
@media (max-width: 768px) {
    .containers {
        padding: 10px;
    }

    .resultrow {
        flex-wrap: wrap;
    }

    .col-3 {
        flex: 0 0 calc(50% - 10px);
    }
}


.contrr444 h2 {
    font-size: 40px;
    color: #fff;
    text-align: center;
}

.contrr444 ul {
    margin: 0;
    padding: 0;
    text-align: center;
}

.fronth2 h2 {
    font-size: 35px;
    font-weight: normal;
    padding: 0;
}

.newbg_contact {
    background: #333;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    opacity: 0.9
}

.footer {
    background: linear-gradient(105deg, #515151, #515151);
    padding-top: 80px;
    padding-bottom: 40px;
}

/*END FOOTER SOCIAL DESIGN*/
.single_footer {}

@media only screen and (max-width:768px) {
    .single_footer {
        margin-bottom: 30px;
    }
}

.single_footer h4 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}

.single_footer h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: #fff;
    margin-top: 20px;
}

.single_footer p {
    color: #fff;
}

.single_footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.single_footer ul li {}

.single_footer ul li a {
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 36px;
    font-size: 15px;
    text-transform: capitalize;
}

.single_footer ul li a:hover {
    color: #04b5ca;
}

.single_footer_address {}

.single_footer_address ul {}

.single_footer_address ul li {
    color: #fff;
}

.single_footer_address ul li span {
    font-weight: 400;
    color: #fff;
    line-height: 28px;
}

.contact_social ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
    display: block;
    position: relative;
    margin-top: 15px;
    width: 100%;
}

.subscribe__input {
    background-color: #fff;
    border: medium none;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: 15px;
    font-weight: 500;
    height: 60px;
    letter-spacing: 0.4px;
    margin: 0;
    padding: 0 150px 0 20px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}

@media only screen and (max-width:768px) {
    .subscribe__input {
        padding: 0 50px 0 20px;
    }
}

.subscribe__btn {
    background-color: transparent;
    border-radius: 0 25px 25px 0;
    color: #01c7e9;
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
}

.subscribe__btn i {
    transition: all 0.3s ease 0s;
}

@media only screen and (max-width:768px) {
    .subscribe__btn {
        right: 0px;
    }
}

.subscribe__btn:hover i {
    color: #04b5ca;
}

button {
    padding: 0;
    border: none;
    background-color: transparent;
    -webkit-border-radius: 0;
    border-radius: 0;
}

/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
    margin-top: 40px;
}

.social_profile ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.social_profile ul li {
    float: left;
}

.social_profile ul li a {
    text-align: center;
    border: 0px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    margin: 0px 5px;
    font-size: 18px;
    color: #fff;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width:768px) {
    .social_profile ul li a {
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .subjkkbutton{
        padding-left: 29rem;
    }
}

@media only screen and (max-width:480px) {
    .social_profile ul li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
    .subjkkbutton{
        padding-left: 13rem;
        position: absolute;
    bottom: 81%;
    }
}

.social_profile ul li a:hover {
    background: #04b5ca;
    border: 1px solid #04b5ca;
    color: #fff;
    border: 0px;
}


.pb-30{
    padding-bottom: 10px;
    border-bottom: 1px solid white;
}