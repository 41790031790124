body {
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  line-height: 26px;
  min-height: 100vh;
  font-family: 'Raleway', sans-serif!important;
   max-width: 100%;
   overflow-x: hidden;
  
}
.footbackgrounddiv{
    background: url(images/footer.jpg) no-repeat;
    width: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: top center;
    min-height: 400px;
    font-size: 16px;
  }
  .actualfooterdiv{
    background: linear-gradient(105deg, #515151, #515151);
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .footcont{
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }
  .footerlogimg{
      width: 200px;
      height: 50px;
  }
  .footcot2{
    padding-top: 15px;
  }
  .singlefooterh4{
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    padding-top: 0;
  }
  .singlefooterafter{
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: #fff;
    margin-top: 20px;
  }
  .singlefooteulll{
    margin: 0;
    padding: 0;
    list-style: none;
    color: #fff;

  }
  .singlefooteulll li a{
    color: #fff;
    
    line-height: 36px;
    font-size: 15px;
    text-transform: capitalize;
    text-decoration: none;

  }
  .subscribeform{
    display: block;
    position: relative;
    margin-top: 15px;
    width: 100%;

  }
  .subscribeinput{
    background-color: #fff;
    border: medium none;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: 15px;
    font-weight: 500;
    height: 60px;
    letter-spacing: 0.4px;
    margin: 0;
    padding: 0 150px 0 20px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;

  }
  .subscribe__btn{
    background-color: transparent;
    border-radius: 0 25px 25px 0;
    color: #01c7e9;
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    padding: 0;
    border: none;
   
  }
  .suscribeicon{
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
  }
  .social_profile{
    margin-top: 40px
  }
  .socialprofileul{
    list-style: outside none none;
    margin: 0;
    padding: 0;

  }
  .socialprofileul li{
    float: left;
  }
  .socialprofileul a{
    text-align: center;
    border: 0px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    margin: 0px 5px;
    font-size: 18px;
    color: #fff;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.2);

  }
  