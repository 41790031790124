body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    line-height: 26px;
    min-height: 100vh;
     font-family: 'Maven Pro', sans-serif;
     max-width: 100%;
     overflow-x: hidden !important;
     
    
}

/* #header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1051;
}

._uppertop {
    color: #fff;
    background-color: #616161;
    font-size: 13px;
    font-family: 'Raleway', sans-serif!important;
    margin: 0;
    padding: 0.938rem 0rem;
    font-weight: 600;
    letter-spacing: normal;
    width: 100%;
    height: 4.25rem;
    
    
}
.container-fluid{
  padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}
.rowcnt2{
  display: flex;
    align-items: center;
    margin-right: -15px;
    margin-left: -15px;

}
._rowsm2{
    padding-left: 9rem;
}

.imgicon {
    width: 16px;
    height: 16px;
}

.mobilenoclass {
    color: #fff;
    margin: 0;
    letter-spacing: normal;
}

._searchbox {
    background-color: #fff;
    color: #0961ce;
    margin: 0;
    padding: 0rem 0rem 0rem 0.625rem;
    width: 16.875rem;
    border: 1px solid #ccc;
    border-radius: 20px;
    height: 2.1875rem;
}

.searchWrapper-variantA {
    display: flex;
    align-items: center;
}

.search-image {
    display: flex;
    align-items: center;
    margin-right: 0.625rem;
    cursor: pointer;
}

.cnt2 {
    display: flex;
    align-items: center;
}

.contact-info {
    display: flex;
    align-items: center;
    margin-right: 0.563rem;
}

.tel {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 19.5px;
    vertical-align: baseline;
    letter-spacing: normal;
}
._dropglob{
    transform: rotate(46deg);
    border: solid white;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
    margin-bottom: 3%;
    
    
    
    
    
}
.newsmeimgclass{
    line-height:26px;
}

.dropdiv {
    background-color: #616161;
    border: 0;
    text-transform: uppercase;
    padding-right: 1.688rem;
    padding-bottom: 0;
    margin-right: 0.375rem;
    display: inline-block;
    box-shadow: none;
    color: #fff !important;
    width: auto;
    height: auto;
    border-style: dotted;
    border-color: white;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    border-style: dotted;
    border-color: white;
    padding: 10  ;
    background-color: #616161;
    display: none;
}

.show .dropdown-menu {
    display: block;
}

.xselectLang {
    margin-left: 0.625rem;
margin-right: 0.625rem;
    color: #fff;
    font-weight: 400;
}

._normline {
}

.extra-cell ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.extra-cell ul li {
    display: inline-block;
}

.extra-cell ul li .btn-link {
    padding: 0 0 0 1.25rem; 
    margin-left: 0.625rem;
    position: relative;
    cursor: pointer;
}

.extra-cell ul li .btn-link:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: #000;
    left: 0;
    top: 0;
    opacity: 0.1;
}

.extra-cell ul li a,
.extra-cell ul li .btn-link {
    padding: 0 0.5714rem;
    color: #000;
}

._rowsm{
    
    float: right;
    position: relative;
    min-height: 0.0625rem; 
    padding-right: 0.9375rem; 
    padding-left: 0.9375rem;;
    color: #fff;
    word-spacing: 0;
    margin: 0;
    padding: 0
}

._topImage{
    background-image: url(/src/component/images/bgimage.jpg);
    transition: padding-top .3s ease;
    height: auto;
    min-height: 733px;
    
    background-size: cover;
    background-position: top right;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.bigbgmaindiv{
   
}
.logoSlice{
    background-color: rgba(0,0,0,.7);
    height: 7rem;
    width: 100%;
    padding: 25px 0px 0px;
    transition: height 0.3s ease;
    
    
}
.stickyLogoSlice {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 68px; 
  width: 100%;
  padding: 10px 0;
  z-index: 1000; 
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); 
}
.logoSlicontain{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.navdiv {
    display: flex;
    flex-direction: row;
    padding-left: 41%;
  }
  
  ._navcon {
    width: 100%;
  }
  
  ._navbar_deafult {
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 1px;
    min-height: 30px;
    margin-left: 49%;
  }
  
  .nav-container {
    padding: 0.625rem 0;
    display: flex;
  flex-direction: row; /* Change to row for left-to-right alignment 
  align-items: center;
  width: 100%;
  }
  
  .nav.navbar-nav {
    display: flex;
  flex-direction: row; /* Change to row for left-to-right alignment 
  align-items: center;
  margin: 0;
  float:right;
  }
  
  ._navbarlist {
    margin: 0;
    padding: 0 0.5rem;
    
  }
  
  ._navlistaaa {
    font-size: 14.944px;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    display: block;
    margin:0px;
padding:17px 0px 17px 15px;
line-height:13px;
letter-spacing:1px;
  }
  
  
  .nav.flex-column .nav-link {
    font-size: 0.934em;
    text-transform: uppercase;
    color: white !important;
    text-decoration: none;
    display: block;
  } */

  .conten1div{
    padding: 22% 0 7rem 2% !important;
    /* padding-left: 8rem !important; */
  /* max-width: 100% !important; */
    /* margin-right: auto; */
    
    
  }
  .sticky-header {
    position: sticky;
    top: 0;
    height: 68px;
    z-index: 1000; /* Adjust the z-index as needed */
    background-color: #fff; /* Add your desired background color */
  }
  .conten1col{
    /* background: rgba(0,0,0, 0.3) !important;
    border-radius: 5px;
    box-shadow: 0px 0px 9px #333; */
    /* padding: 2rem 5rem !important; */
    /* margin-top:10rem !important; */
    padding: 1% ;
    margin-left: 2%;
  }
  /* .conten1row{
    margin-right: -15px;
    margin-left: -15px;
    
  } */
  
  #welcomspan{
    letter-spacing:-1px;
    
    
    margin-top: 0;
    padding-top: 0;
    line-height: 1em;
    padding-bottom: 15px
  }
  .bannerPaddingRight{
    color: white;
  }
  .bannerPaddingRight{
    
  }
  
  #_welcompro{
    font-size:16px;
    line-height:26px;
    margin:0px 0px 10px;
padding:0px;
font-weight:400;
letter-spacing:normal;
  }
  #bttonphome{
    opacity: 1;
    font-weight: 800;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    /* padding: 15px 25px; */
    color: #fff;
    text-transform: uppercase;
    margin-top: 0px;
    /* width: 509px; */
    height: 62.6px;
    
  }
  
  #_whitbutton, #_bluebutton {
    cursor: pointer;
    border: 1px solid #666467;
    border-radius: 22px !important;
    height: 42.6px !important;
    margin: 20px 5px 0px;
    padding: 15px 25px;
    letter-spacing: 2px;
    line-height: 11px;
    word-spacing: 0px;
    font-weight: 800;
    transition: background-color 0.3s, color 0.3s; /* Add transition for smooth effect */
  
    /* Set initial colors */
    background-color: #fff;
    color: #666467;
  }
  
  #_bluebutton {
    background-color: #0a71f0;
    border: 1px solid #9b9b9b;
    color: #fff;
    margin: 20px 0px 0px;
  }
  
  /* Hover effect */
  #_whitbutton:hover {
    background-color: #666467;
    color: #fff;
  }
  #_bluebutton:hover {
   background-color:
    RGB(15, 86, 153);
   

  }
  ._globalcont{
 
    width:100%;
  }
  .gloabrow{
    text-align: center;
    
    line-height:26px;
  }
.gloalid{
  background-color: #333;
}
.gloacol{
letter-spacing:normal;
word-spacing:0px;
padding: 4.688rem;
/* margin-left: 16.66666667%; */

}
.gloabh2{
  font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px;
    margin-top: 0;
    padding-top: 0;
    color: #fff;
}
.globalLeader{
  width: 100%;
  height: 33.863rem;
}
._gloabp{
  color: #fff;
  
     font-weight: 400;
     word-spacing:0px;
margin:0px 0px 10px;
padding:0px;
}
.bluesedulep{
  margin: 0 0 10px;
  font-family: raleway, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #000;
}
#_bluesedulea{
  display: inline-block;
  font-weight: 800;
  line-height: 11px;
  letter-spacing: 2px;
  border-radius: 22px;
  padding: 15px 25px;
  text-transform: uppercase;
  margin-top: 20px;
  background-color: #0a71f0;
    border: 1px solid #9b9b9b;
    color: #fff;
}
#_bluesedulea:hover {
  background-color:
   RGB(15, 86, 153);
  

 }
 .aestheticdoc{
  background-image: url(/src/component/images/doctor.png);
  background-size: cover;
    padding: 150px 0 0 0;
    min-height: 855.4px;
    width: 100%;

 }
 .doccontainer{
  width: 100%;
 }
 .docrow{
  margin-right: -15px;
    margin-left: -15px;
 }
 ._doccol{
  margin-left: 41.66666667%;  
 }
 .whyuseh2{
  margin-top: 0;
    padding-top: 0;
    
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px;
 }
 ._whyp{
  margin: 0 0 10px;
 }
 .whath3{
  margin-top: 0;
    padding-top: 0;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 10px;
    font-weight: 500;
 }
 #btnwhitlearn{
  background-color: #fff;
    border: 1px solid #666467;
    color: #666467;   
     font-weight: 800;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    border-radius: 22px;
    padding: 15px 25px;
    
    text-transform: uppercase;
    margin-top: 20px;
 }
 #btnwhitlearn:hover{
  background-color: #666467;
    color: #fff;

 }
 .devicwt{
  padding: 75px 0;
  text-align: center;
  color: #fff;
  
 }
 .devicecont{
  width: 100%;
  min-height: 967.8px;
 }
 .devicerow{
  padding-bottom: 50px;
  margin-right: -15px;
    margin-left: -15px;
 }
 .deviceilist{

 }
 .deviceheadinh{
  margin-top: 0;
  padding-top: 0;
  font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px;
 }
 .productdevicon{
  padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
 }
 .productdevrow{
  margin-right: -15px;
    margin-left: -15px;

 }
 .producdevice1col{
 
    height: auto;
   
    float: none;
    display: inline-block;
    vertical-align: top;

 }
 .productHoverBorder{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
 }
 .prodImage{
  z-index: 3;
 }
 ._luxaft{
  text-align: center;
  color:#00b5ca;
  font-weight: 700;
 }
 ._Aftstand{
  margin: 0px 0px 0px -7px;
    /* padding: 7px; */
  font-weight:400;
  color:RGB(0, 0, 0);
 }
 #btnlight{
  opacity: 1;
  background-color:white;
    border: 1px solid #666467;
    color:  #666467;;   
     font-weight: 800;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    border-radius: 22px;
    padding: 15px 25px;
    
    text-transform: uppercase;
    margin-top: 20px;

 }
 #btnlight:hover{
  background-color: #666467;
    color: #fff;

 }
 /* .productHover{
  width: 100%;
    overflow: hidden;
    padding: 30px;
    color: #666467;
    transition: all .7s ease-out;
    position: relative;

 } */
 /* .productHover:after{
  border: 10px solid transparent;
    position: absolute;
    top: 225px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    content: '';
    transition: all .7s ease-out;
    position: relative;
 } */
 .speaktoexpertdiv{
  background-color: #f5f4f4;
  padding: 75px 0;
 }

 .spaekcont{
  padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    height: 225.4px;
 }
 /* .colspeak{
  margin-left: 16.66666667%
 } */
 #btncontactus{
  display: inline-block;
  font-weight: 800;
  line-height: 11px;
  letter-spacing: 2px;
  border-radius: 22px;
  padding: 15px 25px;
  text-transform: uppercase;
  margin-top: 20px;
  background-color: #0a71f0;
    border: 1px solid #9b9b9b;
    color: #fff;
 }
 #btncontactus:hover{
   background-color:   RGB(15, 86, 153);
 }
 
 .bradnproduct{
  background: #fff;

 }
 .bradnproductdiv{
  display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #ebe5d9;
  

 }
 .bradnproductrow{
  display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
 }
 .bradnproductcol{
  flex: 0 0 calc(25% - 20px);
    padding: 10px;
    box-sizing: border-box;
    width: 25%;
 }
 .group_img {
  max-width: 100%;
    height: auto;
    border: 2px solid black;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    vertical-align: middle;

 }
 .featurecontain{
  width: 100%;
  padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    /* height: 30rem; */
 }
 .paplaterow{
  display: flex;
 }
 .paplatecol{
 
  padding-left: 0px !important;
 }
 .feature-h2{
  padding: 100px 30px 10px 0px;
  margin-top: 0;
  font-size: 48px;
  
  line-height: 1.1em;
  color: #000;
  
 }
 .feature-p{
  Padding: 50px 30px 30px 0px;
 }
 .best-imagecol{
  padding: 0 !important;
 width: 100%;
 }
 .newbg_contact{
  opacity: 0.9;
  background-color:
  #333333;
  padding: 75px 0;
 }
  .newbgcontactcontain{
    width: 100%;
  }
  .contrr444{
    /* margin-right: -15px;
    margin-left: -15px; */
    text-align: center;
  }
  .contrr4h2{
    font-size: 40px;
    color: #fff;
    text-align: center;
  }
  .contrr44p{
    font-size: 19px;
    color: #fff;
  
  }
  .contrrulll{
    
    padding: 0 !important;
    text-align: center;
    display: inline-flex;
    color: #fff;
    list-style: none;
    margin-left: 0% !important;
    /* margin-left: 5%; */
    

    
  }
  .contr44li{
    margin-bottom: 21px;
    color: #fff;
    font-size: 25px;
    padding: 10px 10px;
  }
  .contr44xs{
    color: #fff;
    padding: 14px 10px;
    font-size: 20px;
  }
  .contactboxrow{
    
background-color: rgba(0, 0, 0, 0.3);
box-shadow: 0px 0px 9px #dcdcdc;
padding-top: 3rem;
padding-bottom: 3rem;
/* margin-top: -13rem !important; */
border-radius: 15pt;
  }
  .fronth2{
    font-size: 35px;
    font-weight: normal;
    padding: 0;
    color: #fff;
  }
  .contactcol{
    width: 100%;
    /* margin-top: 12% !important; */
  }
  .frontppp{
    font-size: 14pt;
    color: #fff;
    margin: 0 0 10px;
  }
  .contctm444{
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .bussinaaa{
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 17pt;
    color: #fff
  }
  .lllll{
    padding-bottom: 50pt;
  }
  .contactlogocontact{
    margin-bottom: 15px;
  font-size: 12pt;
  line-height: 30pt;
  padding-left: 15pt;
  float: left;
 
  }
  .contactlogoimg{
    width: 25px;
    height: 25px;
    vertical-align: middle;
    border: 0;
  }
  .contactlogoaaaa{
    color: #fff;
  }
  .footbackgrounddiv{
    background: url(images/footer.jpg) no-repeat;
    width: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: top center;
    min-height: 400px;
    font-size: 16px;
    margin-bottom: -16px;
  }
  /* .footbackgrounddiv{
    background: url(images/footer.jpg) no-repeat;
    width: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: top center;
    min-height: 400px;
    font-size: 16px;
  }
  .actualfooterdiv{
    background: linear-gradient(105deg, #515151, #515151);
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .footcont{
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }
  .footerlogimg{
      width: 200px;
      height: 50px;
  }
  .footcot2{
    padding-top: 15px;
  }
  .singlefooterh4{
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    padding-top: 0;
  }
  .singlefooterafter{
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: #fff;
    margin-top: 20px;
  }
  .singlefooteulll{
    margin: 0;
    padding: 0;
    list-style: none;
    color: #fff;

  }
  .singlefooteulll li a{
    color: #fff;
    
    line-height: 36px;
    font-size: 15px;
    text-transform: capitalize;
    text-decoration: none;

  }
  .subscribeform{
    display: block;
    position: relative;
    margin-top: 15px;
    width: 100%;

  }
  .subscribeinput{
    background-color: #fff;
    border: medium none;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: 15px;
    font-weight: 500;
    height: 60px;
    letter-spacing: 0.4px;
    margin: 0;
    padding: 0 150px 0 20px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;

  }
  .subscribe__btn{
    background-color: transparent;
    border-radius: 0 25px 25px 0;
    color: #01c7e9;
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    padding: 0;
    border: none;
   
  }
  .suscribeicon{
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
  }
  .social_profile{
    margin-top: 40px
  }
  .socialprofileul{
    list-style: outside none none;
    margin: 0;
    padding: 0;

  }
  .socialprofileul li{
    float: left;
  }
  .socialprofileul a{
    text-align: center;
    border: 0px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    margin: 0px 5px;
    font-size: 18px;
    color: #fff;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.2);

  } */
  .best-image{
    width: 100%;
    height: 100%;

  }
  .speakh2{
    font-size: 48px;
     
      line-height: 1.1em;
      color: #000;
      padding-bottom: 10px;
      padding-top: 0;
  }
@media (min-width: 576px) {
    ._firstid {
        flex: 0 0 auto;
        float: left;
        position: relative;
        min-height: 0.0625rem;
        padding-right: 0.9375rem; 
        padding-left: 0.9375rem; 
    }
    ._gloabp{
      font-size: 0.938em;
      }
      .whyuseh2{
        font-size: 2.375em
      }
      ._whyp{
        font-size: 0.938em;
      
      }
      ._doccol{
        margin-left:43% !important;
        
    }
    .speakh2{
      font-size: 48px;
    }
    
    ._textal{
      text-align: center 
    }
    #welcomspan{
      font-size: 3em;
    line-height: 1em; 
    }
    .conten1div{
     
        /* margin-right: auto; */
        
        
      }.contactcol{
        /* margin-top: 12% !important; */
      }
      .contrrulll{
        margin-left: 14%  ;
       
      }
      .contr44li {
        padding:10px 32px
      }
    
}
@media only screen and (max-width:480px) {
  
   
    .conten1col{
      background: rgba(0,0,0, 0.3) !important;
        border-radius: 5px;
        box-shadow: 0px 0px 9px #333;
    
      text-align: center;
      
        background: rgba(0,0,0, 0.3);
    height: 25rem;
    
    max-width: 93% !important;      
      }
}

@media (min-width: 768px) and (max-width: 1023px) {
    ._firstid {
        float: left;
    }
    ._navbar_deafult {
        display: flex;
        justify-content: flex-end;
      } 
      .rowspeak{
      }
      .conten1div{
    
    
      
}
.paplatecol{
  width: 100% !important;
  }
  .best-imagecol{
    width: 100% !important;
  }

._welcompro{
 
}


.conten1col{
  background: rgba(0,0,0, 0.3) !important;
    border-radius: 5px;
    box-shadow: 0px 0px 9px #333;

  text-align: center;
  
    background: rgba(0,0,0, 0.3);

    padding-right: 50px;
    padding-left: 50px;
    
    }
._textal{
  text-align: center !important;
}
.twldldll{
  font-size: 16px;
    line-height: 26px;
    color: #000;
    
}
#welcomspan{
font-size: 3em;
line-height: 1em;
}
.speakh2{
  font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px;
    padding-top: 0;
}
.gloabrow{
  
}
._doccol{
  margin-left:20% !important;
  
}
.gloacol{
  margin-left:16.66667% ;
}
.gloabh2{
  font-size: 2.375em;
}
._gloabp{
  font-size: 0.938em;
  }

  .whyuseh2{
    font-size: 2.375em !important;
  }
  ._whyp{
    font-size: 0.938em;
  
  }
.whyuseh2{
  font-size: 1.5em;
}
.producdevice1col{
  width: 49.333% !important;
}
.colspeak{
  margin-left: 16.66666667%
 }
 .contactcol{
  /* margin-top: 12% !important; */
}
.contrrulll{
  margin-left:  0%;
}

}
@media screen and (min-width: 1024px)
{
.logoSlice {
    padding-bottom: 0;
}
.conten1col{
  width: 66.66666667%;
  margin-top: 10%;
}
#welcomspan{
  font-size: 4em;
}
._gloabp{
  font-size: 0.938em;
  }

  .whyuseh2{
    font-size: 2.375em !important;
  }
  ._whyp{
    font-size: 0.938em;
  
  }
  .whyuseh2{
    font-size: 1.5em;
  }
  .producdevice1col{
    width: 49.333%;
  }
  .colspeak{
    margin-left: 16.66666667%
   }
   .speakh2{
    font-size: 38px;
}
.contactcol{
  /* margin-top: 12% !important; */
}
.contrrulll{
  margin: 0%;
}

}
@media (min-width: 992px){
    .conten1col{
      width: 66.66666667% !important;
      height: 100%;
      text-align: center;
      background:none !important;
    border-radius: 0px;
    box-shadow: 0px 0px ;
    }
    #welcomspan{
      font-size: 4em;
    }
    .gloacol{
      margin-left: 16.66666667% !important;
    }
    .colspeak{
      margin-left: 16.66666667%
     }
     .newbgcontactcontain{
      width:100%;
    }
    .conten1div{
      
    /* padding: 22% 0 7rem ;
    padding-left: 8rem !important; */
    }
    .gloabh2{
      font-size: 2.375;
    }
    ._gloabp{
      font-size: 0.938em;
      }
    
      .whyuseh2{
        font-size: 2.375em;
      }
      ._whyp{
        font-size: 0.938em;
      
      }
      .whath3{
        font-size: 1.5em;
      }
      .producdevice1col{
        width: 49.333%;
      }
      .speakh2{
        font-size: 48px;
}
.contactcol{
  /* margin-top: 12% !important; */
}
.contrrulll{
  margin: 0 !important;
}

}
@media (max-width: 1200px){
  .best-image{
    
    height: 100%;

  }
  .conten1col{
    width: 66.66666667%;
    /* margin-top: 10%; */
}
.aestheticdoc{
  background-image: none;
}
._doccol{
    margin-left:3% ;
    margin-right: 3%;
    
}
._whyp{
  
}.conten1div{
      
  margin-top: 36%;
  }
  
  .gloabh2{
    font-size: 2em;
  }
._gloabp{
  font-size: 0.875
}
.whyuseh2{
  font-size: 1.75em
}
._whyp{
  font-size:0.875em;

}
.whath3{
  font-size:1.12em;
}
.homepicss{
  width:9.375rem ;
  height:236.86rem ;
}
.speakh2{
  font-size: 28px;
}
.paplatecol{
  width: 100% !important;
  }
  .best-imagecol{
    width: 100% !important;
  }
  .contactcol{
    /* margin-top: 22% !important; */
  }
  .contrrulll{
    margin-left: 0% !important;
    
}

}
@media (min-width: 119px){
  .conten1col{
    background: rgba(0,0,0, 0.3) !important;
        border-radius: 5px;
        box-shadow: 0px 0px 9px #333;
    
      text-align: center;
  }
}