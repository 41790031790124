body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    line-height: 26px;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif!important;
     max-width: 100%;
     overflow-x: hidden;
    
}