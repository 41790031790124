.searchBox::placeholder{padding-left:5px}.search-container .items{display:flex;align-items:center;margin-top: 1rem}.search-container img{margin-left:10px}.search-container img:hover{cursor:pointer}
.searchBoxOpen{
    width: 270px;
    border-radius:20px !important;
    border:0;padding:0;padding-left:10px;height:35px
   
}
.searchBox{ display:none; border-radius:20px;
    border:0;padding:0;padding-left:10px;height:35px;-moz-transition:all .2s ease!important;-o-transition:all .2s ease!important;-ms-transition:all .2s ease!important;transition:all .2s ease!important}

