body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    line-height: 26px;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif!important;
     max-width: 100%;
     overflow-x: hidden;
    
}
a:focus,
button:focus {
    text-decoration: none;
     
}

.searchPanelOpen {
    color: #fff;
    background: 0 0;
    border: none
}

#footerslice a:focus,
#footerslice button:focus {
    text-decoration: none !important;
    color: #fff
}

.footerSlice .footermenutitle {
    font-size: 16px
}

.footerSlice .nav a {
    font-size: 16px
}

.footer-regulatory {
    font-size: 14px
}

.navbar-container {
    position: relative; 
    z-index: 2; 
  }
.navbar-default .navbar-nav>li>a:focus {
    
    transition: unset !important
}

.top-utility-nav a:focus {
    text-decoration: none;
    
    background-color: unset !important
}

.footerSlice .nav a:focus {
    background-color: unset;
    color: unset
}

.btn {
    font-size: 12px !important
}

.results .carousel-indicators .active {
    outline: 1px dashed #000 !important
}

.productCarouselNew .caro-title {
    color: #000 !important
}

.productCarouselNew .padding25 {
    color: #666 !important
}

.productCarouselNew span {
    color: #545454 !important
}

.screen-reader-text {
    color: #000
}

button.selectLang {
    border: 0;
    text-transform: uppercase;
    padding-right: 27px;
    padding-bottom: 0;
    margin-right: 6px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 18px 11px;
    display: inline-block;
    box-shadow: none;
    color: #fff !important;
    background-color: unset;
    box-shadow: none !important
}



#header .mini-grey-slice .btn-group button.selectLang.dropdown-toggle .dropdown {
    transform: rotate(46deg);
    border: solid #fff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 3px
}

.megamenu-button {
    background: unset;
    border: unset;
    padding: 5px 10px 10px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 1px;
    color: #fff !important;
    text-transform: uppercase;
    font-size: .734em
}

.megamenu-button:focus {
    /* !important */
}

.show-menu {
    display: block !important
}

#header .dropdown-menu li>a:focus {
    /* !important; */
    transition: unset !important
}

#header .dropdown-menu .btn-blue:focus {
    /* !important */
}

.megamenu-wrapper .button-link {
    width: 46.8%;
    text-align: center;
    border-top: 2px solid gray;
    text-transform: uppercase;
    margin-top: 50px;
    margin-right: auto
}

body.location_en-gl .headerARIA .navbar-default li.dropdown:hover>ul.dropdown-menu,
body.location_en-us .headerARIA .navbar-default li.dropdown:hover>ul.dropdown-menu,
body.location_en-ca .headerARIA .navbar-default li.dropdown:hover>ul.dropdown-menu,
body.location_fr-ca .headerARIA .navbar-default li.dropdown:hover>ul.dropdown-menu,
body.location_il #header .navbar-default li.dropdown:hover>ul.dropdown-menu {
    /* display: none */
}

.dropdown-button {
    background: unset;
    border: unset;
    text-transform: uppercase;
    padding: 5px 10px 10px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 1px;
    color: #fff !important;
    font-size: .734em
}

body.location_il .dropdown-button {
    font-size: .9em
}

.dropdown-button:focus {
   
}

body.location_en-gl #header .mini-grey-slice .btn-group a.selectLang.dropdown-toggle,
body.location_en-ca #header .mini-grey-slice .btn-group a.selectLang.dropdown-toggle,
body.location_en-us #header .mini-grey-slice .btn-group a.selectLang.dropdown-toggle,
body.location_fr-ca #header .mini-grey-slice .btn-group a.selectLang.dropdown-toggle {
    padding: 5px 27px 5px 0;
    background-position: right 6px
}

body.location_en-gl .top-utility-nav a {
    transition: unset;
    padding: 5px 0
}

body.location_en-us .treatment-menu .dropdown[data-level='2'] {
    width: 30%
}

.navbar-nav .dropdown-menu li .menu-title {
    font-weight: 600 !important;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    letter-spacing: 0;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 20px;
}
