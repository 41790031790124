body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    line-height: 26px;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif!important;
     max-width: 100%;
     overflow-x: hidden;
    
}
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');

#bttonp {
    opacity: 1;
    font-weight: 800;
    text-align: center;
    line-height: 11px;
    letter-spacing: 2px;
    padding: 15px 25px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 0px;
    width: 509px;
    height: 62.6px;
    margin-left: -6rem;
  }
  
  #_whitbutton, #_bluebutton {
    border: 1px solid #666467;
    border-radius: 22px !important;
    height: 42.6px !important;
    margin: 20px 5px 0px;
    padding: 15px 25px;
    letter-spacing: 2px;
    line-height: 11px;
    word-spacing: 0px;
    font-weight: 800;
    transition: background-color 0.3s, color 0.3s; /* Add transition for smooth effect */
  
    /* Set initial colors */
    background-color: #fff;
    color: #666467;
  }
  
  #_bluebutton {
    background-color: #0a71f0;
    border: 1px solid #9b9b9b;
    color: #fff;
    margin: 20px 0px 0px;
  }
  
  /* Hover effect */
  #_whitbutton:hover {
    background-color: #666467;
    color: #fff;
  }
  #_bluebutton:hover {
   background-color:
    RGB(15, 86, 153);
   

  }

.commoncol6img {
    box-shadow: rgba(255, 255, 255, 0.16) 0px 10px 36px 0px, rgba(255, 255, 255, 0.06) 0px 0px 0px 1px;
}

span.bshape {
    font-size: 40px !important;
    color: #fff !important;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 15px;
}

span.ultimate {
    font-size: 60px !important;
    color: #fff;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 0px 15px;
}
.conten1colget{
    width: 100%;
   
     margin-top:200px;
     background: rgba(0,0,0, 0.3);
     border-radius: 5px;
     box-shadow: 0px 0px 9px #333;
     padding: 2rem 5rem ;
     margin-top:10rem ;
     margin-left: 7rem;
}

@media only screen and (max-width: 767px) {
    span.ultimate {
        font-size: 40px !important;
    }
}


.contactusbtn {
    background-color: #0a71f0;
    text-align: center;
    line-height: 11px;
    border-radius: 22px;
    padding: 15px 25px;
    color: #fff;
    margin-top: 20px;
}

.getfitbgimg {
   
    background-image: url(./images/GF1.jpg);
    transition: padding-top .3s ease;
    height: auto;
    min-height: 733px;
    
    background-size: cover;
    background-position: top right;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.getfit-container {
    
 
    padding: 70px 0px 50px 0px;
    height: 100vh !important;
}

@media only screen and (max-width: 767px) {
    .getfit-container {
        height: 100% !important;
    }
}



/* .getfit::after{
    
} */

.high_intensity {
    text-align: center;
}

.getfit-logo {
    padding: 80px 60px;
}


@media only screen and (max-width: 767px) {
    .getfit-logo {
        padding: 30px !important;
        text-align: left;
    }

    .getfit-logo h2 {
        text-align: left;
    }

    .getfit-logo h5 {
        text-align: left;
    }
    
}

/* Create rows using Flexbox for better responsiveness */
.rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* Define the width of columns for different screen sizes */
.col-6 {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

@media (min-width: 768px) {

    /* On screens larger than 768px, make columns 50% width */
    .col-6 {
        width: 50%;
    }
}

/* Style for the empowering text */
.empowering {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #a5adb7d9;
    padding: 50px;
    overflow: hidden;
    /* Ensure rounded corners are applied properly */
}

@media only screen and (max-width: 767px) {
    .empowering {
        padding: 30px 0px 30px 0px !important;
    }
}

/* Add spacing and center image within its container */
.empoweringcol6 img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

.empoweringcol6 h2 {
   color: #000000;
   font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    
    padding-bottom: 10px;
}
.empoweringcol6 p {
 color: #000000;
}

@media only screen and (max-width: 767px) {
    .empoweringcol6 img {
        max-width: 90%;
    }
}

/* Style for the feature paragraph */
.feature-p {
    margin-top: 20px;
    line-height: 1.6;
    color: #666;
}

.tailoredcol6 {
    padding: 50px;
}

@media only screen and (max-width: 767px) {
    .tailoredcol6 {
        padding: 20px;
    }
}

.crafingcol6 {
    padding: 50px;
    background-color: #fff;
}

.crafingcol6 h2 {
    /* color: #0bbfcf; */
}
.magneticstimulationgetfit{
    padding: 0px 0px 0px 0px;
}

.tailoredgetfit{
    z-index: 2;
    background-image: url(images/young.webp);
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: cover;
    background-attachment: fixed;
}

.younggetfit {
    background-color: #a5adb7 !important;
    padding: 100px 80px ;
    width: auto !important;
    background-attachment: initial !important;
    background-size: contain;
    background-position: 0 50% !important;
    background-repeat: no-repeat !important;
    background-image: url(images/gym.jpg);
}

@media only screen and (max-width: 767px) {
    .young {
        padding: 0px !important;
    }
   
}

/* power of science */

.powerofsciencegetfit {
    background-color: #a5adb7 !important;
    padding: 100px 80px;
    width: auto !important;
    background-attachment: initial !important;
    
    /* background-position: 0 50% !important; */
    background-repeat: no-repeat !important;
    background-image: url(images/hotcouple44.jpg);
}

@media only screen and (max-width: 767px) {
    .powerofscience {
        padding: 40px 40px;
    }
}

.powercol6 {
    padding: 50px;
    background: #fff;
    box-shadow: 0px 0px 9px #fff;
}

.powercol6 h2 {
    /* color: #0bbfcf; */
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px;

}

.magneticstimulationgetfit {
    background-color: #fff !important;
    padding: 100px 80px;
    width: auto !important;
    background-attachment: initial !important;
    background-size: cover !important;
    background-position: 0 50% !important;
    background-repeat: no-repeat !important;
    background-image: url(images/Hotcouple2.jpg);
    background-position: center;
    background-attachment: fixed !important;
}

.magneticstimulation h2 p {
    text-align: center;
    /* color: #0bbfcf; */
}

.magneticstimulationh2 {
    /* color: #0bbfcf; */
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px;

}

@media only screen and (max-width: 767px) {
    .magneticstimulation {
        padding: 40px 40px;
    }

    .magneticstimulation h2 {
        text-align: left;
    }

    .magneticstimulation p {
        text-align: left;
    }
}

.embracinggetfit {
    background-color: #fff !important;
    padding: 100px 80px;
    width: auto !important;
    background-attachment: initial !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(images/hotcouple5.jpg);
}

@media only screen and (max-width: 767px) {
    .embracing {
        padding: 40px 40px;
    }
}

.embracingcol6 {
    padding: 50px;
    background: #ffffffdb;
    box-shadow: 0px 0px 9px #fff;
}

.embracingcol6 h2 {
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1em;
    color: #000;
    padding-bottom: 10px;

}

.symphonyofgetfit {
    background-color: #fff !important;
    padding: 100px 80px;
    width: auto !important;
    background-attachment: initial !important;
    background-size: cover !important;
    background-position: 0 50% !important;
    background-repeat: no-repeat !important;
    background-image: url(images/happy.webp);
    background-position: center;
    background-attachment: fixed !important;
}

@media only screen and (max-width: 767px) {
    .symphonyofgetfit {
        padding: 0px;
    }
    
}

.empowerbusiness {
    background-image: url(images/young.webp);
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: cover;
    background-attachment: fixed;
}

.getfit-head{
    background: rgba(0,0,0, 0.3);
    border-radius: 5px;
    box-shadow: 0px 0px 9px #333;
    padding: 2rem 5rem !important;
    margin-top:10rem !important;
}

@media only screen and (max-width: 767px) {
    .getfit-head {
        padding: 1rem 2rem;
    }
     .conten1colget{
        padding: 4% 2% 2% 0%;
        margin-left: 0;
    }
    .newsmeimgclass{
        margin-left: 10%;
    }
    .empoweringcol6 h2{
        font-size: 41px;
    }
    .younggetfit{
        background-size: 100% 100%;
        padding: 0;
    }
   .magneticstimuladiv2222{
    display: none;
   }
   .powerofsciencegetfit{
        padding: 0;
   }
   .embracinggetfit{
    padding: 0;
    
}
.empoweringcol622{

}
.commoncol6img{
        
}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .conten1colget{
        padding: 4% 2% 2% 0%;
        margin-left: 0;
    }
    .younggetfit{
        background-size: 100% 100%;
    padding: 0;
    }
    .powerofsciencegetfit{

    }
    .embracinggetfit{
          padding: 0;
    }
    .embracingcol6{

    }
    .empoweringcol622{
        
    }
    .commoncol6img{
        
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {

}
@media only screen and (min-width: 1200px) and (max-width: 1279px){

}
@media screen and (min-width: 1280px){

}